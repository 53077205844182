/* eslint-disable  @typescript-eslint/no-explicit-any */

import { AxiosHeaders, AxiosRequestConfig, AxiosResponse, HttpStatusCode, RawAxiosResponseHeaders } from 'axios';
import { SubmissionContext } from 'vee-validate';
import { FetchListOptions } from '~/api/_types';
import { DeepPartialObject } from '~/utils/_types';

// > Config
export interface ApiConfig extends Pick<AxiosRequestConfig, 'headers' | 'withCredentials' | 'timeout'> {
    baseURL: string;
    endpointUrl?: string;
}

export interface ApiServiceOpts {
    axios?: ApiRequestConfig;
    debug?: boolean;
    notifyOnError?: boolean;
    public?: boolean;
    onAuthErrorFn?: () => Promise<void>;
}

// > Axios request custom "config"
export interface ApiRequestConfig
    extends Pick<
        AxiosRequestConfig,
        | 'data'
        | 'baseURL'
        | 'headers'
        | 'responseType'
        | 'cancelToken'
        | 'onDownloadProgress'
        | 'onUploadProgress'
        | 'timeout'
        | 'params'
    > {
    endpointUrl?: string;
    transform?: ApiResponseTransformer;
    transformMeta?: ApiResponseTransformer;
    notifyOnError?: boolean;
}

// > Models
export enum HttpStatusCodeExtended {
    CsrfTokenMismatch = 419,
}
export type ApiStatusCode = HttpStatusCode | HttpStatusCodeExtended;

export type ApiGenericValues = Record<string, any>;

export interface ApiGenericErrorsData<TData = unknown> {
    Status: number;
    Message: string;
    Data: TData;
}

export type ApiErrors<T = ApiGenericValues> = Partial<Record<keyof T, string | undefined>> & {
    _reason: string;
};

export interface ApiResponseContext<TData, TErrors = unknown, TMeta = unknown> {
    data: TData;
    status: ApiStatusCode;
    meta: TMeta;
    message: string;
    isSuccess: boolean;
    isValidationError: boolean;
    isAuthError: boolean;
    isNotFoundError: boolean;
    errors: TErrors;
    headers: RawAxiosResponseHeaders | AxiosHeaders;
}

export type ApiSimpleResponseContext<T> = Pick<ApiResponseContext<T>, 'data' | 'isSuccess'>;

export type ApiSimpleWithErrorsResponseContext<T, D = unknown> = Pick<
    ApiResponseContext<T, D>,
    'data' | 'errors' | 'isSuccess'
>;

export type ApiSimpleWithStatusResponseContext<T, D = unknown> = Pick<
    ApiResponseContext<T, D>,
    'data' | 'status' | 'isSuccess'
>;

export type ApiSimpleFormResponseContext<T, D> = Pick<
    ApiResponseContext<T, D>,
    'data' | 'errors' | 'isValidationError' | 'isSuccess'
>;

export type ApiResponse<T = any, D = any, M = any> = ApiResponseContext<T, D, M> & AxiosResponse<T, D>;

// > Function prototypes
export type ApiResponseTransformer<T = unknown> = (data: any) => T;

export type ApiHandler<R = unknown> = () => Promise<R>;

// With optional data (args - nullable|undefined|default values)
export type ApiHandlerWithArgs<T, R = void> = (args?: T) => Promise<R>;

// With required data (args)
export type ApiHandlerWithData<T extends ApiGenericValues | string | boolean | number, R = void> = (
    values: T
) => Promise<R>;

//Similar to ApiHandler with data but with interfering in mind
export type ApiHandlerWithDataParams<T extends ApiGenericValues> = T & { opt?: FetchListOptions };

export type FormHandler<T extends ApiGenericValues, R = void> = (
    values: T,
    // we are using zod and it returns deep partial object ctx
    ctx: SubmissionContext<DeepPartialObject<T>>
) => Promise<R>;

export type FormHandlerWithArgs<T, V extends ApiGenericValues, R = void> = (
    args: T,
    values: V,
    // we are using zod and it returns deep partial object ctx
    ctx: SubmissionContext<DeepPartialObject<V>>
) => Promise<R>;

export type SubmissionCtxParam<T> = SubmissionContext<DeepPartialObject<T>>;

export const isApiGenericErrorsData = (data: unknown): data is ApiGenericErrorsData => {
    return data != null && typeof data === 'object' && 'Status' in data && 'Message' in data;
};

export type ContentDisposition = { filename: string | null };
