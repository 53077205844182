import { AppUserType } from '~/enums/permissions/_types';

export const usePermissionsInstitutions = () => {
    const { can } = usePermissions();

    const canCreate = () => can(AppUserType.Admin);
    const canChangeState = () => can(AppUserType.Admin);
    const canEdit = () => can(AppUserType.Admin);
    const canOverview = () => can(AppUserType.Admin, AppUserType.Client);
    const canDetail = () => can(AppUserType.Admin);

    return {
        canCreate,
        canChangeState,
        canEdit,
        canOverview,
        canDetail,
    };
};
