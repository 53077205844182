import { isEmpty as isEmptyLodash } from 'lodash-es';
/**
 * Check if char is one of the characters for possible number
 * @param char char
 */
export const isInputNumberChar = (char: string): boolean => {
    return !!char.match(/^[\d.,-]$/);
};

/**
 * Check if val is a potential number string (matches also incomplete number string)
 * @param val value
 */
export const isInputNumber = (val: string): boolean => {
    return !!val.match(/^[-]?(\d+([.,]\d*)?)?$/);
};

/**
 * Split string by new line
 * @param val
 */
export const splitByNewLine = (val: string): string[] => {
    return val.split(/\r?\n/);
};

/**
 * @param e Keyboard event
 * @returns true if enter on keyboard was pressed
 */
export const isKeyEnter = (e: KeyboardEvent): boolean => {
    const code = e.code || e.key;
    return code === 'Enter' || code === 'NumpadEnter';
};

/**
 * Converts number string to number (returns null if string is NaN)
 * @param val
 * @returns
 */
export const convertToNumber = (val?: string | null): number | null => {
    if (val == null) return null;
    const num = +val;
    return isNaN(num) ? null : num;
};

/**
 * Get Input Error key from name (Laravel validation format X.Y.Z)
 * @param name
 */
export const getInputErrorKey = (name: string) => name.replace(/[[\].]+/g, '.').replace(/\.*$/, '');

/**
 * get vee validate error key format from laravel error keyt format
 * @param errorKey
 * @returns vee validate compatible error key format
 */
export const getVeeValidateErrorKeyFromLaravel = (errorKey: string) =>
    errorKey.replace(/(?<=\.)(\d+)(?=\.)/g, '[$1]').replace(/\.\[/g, '[');

/**
 *
 * @param val val to check
 * @param searchPhrase  search phrase
 * @returns true if val starts with search phrase
 */
export const autocompleteFilter = (val: string, searchPhrase: string): boolean => {
    return val.toLowerCase().includes(searchPhrase);
};

/**
 * fix for lodash (primitive value/function type return false instead of true)
 * @param val val
 * @returns
 */
export const isEmpty = (val: unknown): boolean => {
    const type = typeof val;
    if (type !== 'object' && type !== 'string' && type !== 'undefined') return false;

    return isEmptyLodash(val);
};

export const copyToClipboard = async (text: string) => {
    if (!window.isSecureContext || !navigator.clipboard) {
        console.warn('Clipboard is not available in not secured context.');
        return false;
    }

    await navigator.clipboard.writeText(text);
    return true;
};
