import { z } from 'zod';
import { AppPlugin } from '~/plugins/_types';
import { localizedErrorMap } from './locale';

export default {
    name: 'zod',
    install: async () => {
        z.setErrorMap(localizedErrorMap);
    },
    priority: 3,
} satisfies AppPlugin;
