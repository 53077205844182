<script lang="ts" setup>
import { getCssVar, QBtnSlots } from 'quasar';
import { FlatBtnProps } from './_types';

const props = withDefaults(defineProps<FlatBtnProps>(), {
    activeColor: 'secondary',
    textColor: 'primary',
    square: true,
});
const activeColor = computed(() => getCssVar(props.activeColor || '') || props.activeColor);
const textColor = computed(() => getCssVar(props.textColor || '') || props.textColor);
</script>
<template>
    <q-btn
        v-bind="props"
        class="app-btn app-btn-flat app-btn--no-q-hover text-bold"
        :class="{ 'app-btn--small-icon': smallIcons }"
        flat
        :text-color="textColor"
        :style="{ '--app-btn-flat--text-color': textColor, '--app-btn-flat--active-color': activeColor }"
        no-caps
        :ripple="false"
    >
        <q-tooltip v-if="tooltip" :square="square">{{ tooltip }}</q-tooltip>

        <template
            v-for="(_, slotName) in $slots as Readonly<QBtnSlots>"
            #[slotName]="// @ts-ignore
        slotProps"
        >
            <slot :name="slotName" v-bind="slotProps || {}" />
        </template>
    </q-btn>
</template>
