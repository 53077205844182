import { StudentDetail } from '~/api/students/_types';

export const useStudentDetailStore = defineStore('student', () => {
    const activeStudent = ref<StudentDetail | null>(null);

    const reset = () => {
        activeStudent.value = null;
    };

    return {
        activeStudent,
        reset,
    };
});
