import {
    deleteCertificateQueueRequest,
    fetchCertificateDetailRequest,
    fetchCertificatesQueueRequest,
    fetchCertificatesRequest,
    postCertificatePrintRequest,
    postCertificateQueuePrintRequest,
    postCertificateQueueRequest,
    putCertificateQueueReorderRequest,
} from '~/api/certificates';
import { CertificateDetail, CertificatesQueueInfo, FetchCertificatesResponse } from '~/api/certificates/_types';
import { FetchDetailOptions, FetchListOptions } from '~/api/_types';
import { ApiHandlerWithData, ApiSimpleResponseContext } from '~/plugins/apiClient/_types';
import router from '~/plugins/router';
import { useCertificateDetailStore } from '~/stores/certificateDetail';
import { downloadFromBlob, fetchAndStoreData } from '~/services/utils';
import { LanguageCode } from '~/api/links/_types';
import { parseContentDisposition } from '~/plugins/apiClient/utils';

export const fetchCertificates = async (
    opt?: FetchListOptions
): Promise<ApiSimpleResponseContext<FetchCertificatesResponse | null>> => {
    return await fetchCertificatesRequest(opt);
};

export const fetchCertificatesQueue = async (): Promise<ApiSimpleResponseContext<CertificatesQueueInfo | null>> => {
    return await fetchCertificatesQueueRequest();
};

export const fetchCertificateDetail = async (
    id: number,
    opt?: FetchDetailOptions
): Promise<ApiSimpleResponseContext<CertificateDetail | null>> => {
    return await fetchAndStoreData(
        router,
        'Id',
        id,
        getActiveCertificate,
        async () => await fetchCertificateDetailRequest(id),
        opt
    );
};

export const addCertificateToQueue: ApiHandlerWithData<number, ApiSimpleResponseContext<null>> = async (id) => {
    return await postCertificateQueueRequest(id);
};

export const removeCertificateFromQueue: ApiHandlerWithData<number, ApiSimpleResponseContext<null>> = async (id) => {
    return await deleteCertificateQueueRequest(id);
};

export const printCertificateQueue: ApiHandlerWithData<LanguageCode, ApiSimpleResponseContext<null>> = async (code) => {
    const response = await postCertificateQueuePrintRequest(code);
    const fileName = parseContentDisposition(response).filename;

    if (response.data && fileName) {
        downloadFromBlob(new Blob([response.data], { type: response.headers['content-type'] }), fileName);
    }

    return response;
};

export const printCertificate: ApiHandlerWithData<number, ApiSimpleResponseContext<null>> = async (id) => {
    return await postCertificatePrintRequest(id);
};

export const reorderCertificateQueue: ApiHandlerWithData<
    { code: LanguageCode; reorder: number[] },
    ApiSimpleResponseContext<null>
> = async ({ code, reorder }) => {
    return await putCertificateQueueReorderRequest({ code, reorder });
};

const getActiveCertificate = () => storeToRefs(useCertificateDetailStore()).activeCertificate;
