import { z } from 'zod';
import { AppUserTypeSchema } from '~/enums/permissions/_types';
import { LocaleSchema } from '~/plugins/i18n/_types';
import { zLuxonDateTime } from '~/plugins/zod/utils';
import { AppUserLinkSchema } from '~/api/links/_types';
import { API_DATE_FORMAT } from '~/constants/_constants';

export const AnnouncementFormDataSchema = z.object({
    Subject: z.record(LocaleSchema, z.string()),
    Body: z.record(LocaleSchema, z.string()),
    VisibleFrom: zLuxonDateTime(),
    VisibleTo: zLuxonDateTime(),
    UserTypes: AppUserTypeSchema.array().min(1),
});

export const AnnouncementDashboardInfoSchema = z.object({
    Id: z.number(),
    Subject: z.record(LocaleSchema, z.string()),
    Body: z.record(LocaleSchema, z.string()),
    VisibleFrom: zLuxonDateTime({ format: API_DATE_FORMAT }),
    VisibleTo: zLuxonDateTime({ format: API_DATE_FORMAT }),
    UserTypes: AppUserTypeSchema.array(),
    CreatedAt: zLuxonDateTime({ iso: true }),
});

export const AnnouncementInfoSchema = z.object({
    Id: z.number(),
    Subject: z.record(LocaleSchema, z.string()),
    Body: z.record(LocaleSchema, z.string()),
    CreatedBy: AppUserLinkSchema,
    VisibleFrom: zLuxonDateTime({ format: API_DATE_FORMAT }),
    VisibleTo: zLuxonDateTime({ format: API_DATE_FORMAT }),
    UserTypes: AppUserTypeSchema.array(),
    CreatedAt: zLuxonDateTime({ iso: true }),
    DeletedAt: zLuxonDateTime({ iso: true }).nullable(),
});

export const AnnouncementsArrayDashboardInfoSchema = AnnouncementDashboardInfoSchema.array();
export const AnnouncementsArrayInfoSchema = AnnouncementInfoSchema.array();

export type AnnouncementFormData = z.infer<typeof AnnouncementFormDataSchema>;
export type AnnouncementDashboardInfo = z.infer<typeof AnnouncementDashboardInfoSchema>;
export type AnnouncementInfo = z.infer<typeof AnnouncementInfoSchema>;
export type AnnouncementsArrayDashboardInfo = z.infer<typeof AnnouncementsArrayDashboardInfoSchema>;
export type AnnouncementsArrayInfo = z.infer<typeof AnnouncementsArrayInfoSchema>;
