import { AppFolderDetail } from '~/api/folders/_types';

export const useAppFolderDetailStore = defineStore('app-folder', () => {
    const activeFolder = ref<AppFolderDetail | null>(null);

    const reset = () => {
        activeFolder.value = null;
    };

    return {
        activeFolder,
        reset,
    };
});
