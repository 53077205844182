import { z } from 'zod';
import { zFile, zGuid, zLuxonDateTime } from '~/plugins/zod/utils';
import { AppUserLinkSchema, LanguageCodeSchema } from '~/api/links/_types';

export const AppFileInfoSchema = z
    .object({
        Id: zGuid(),
        Name: z.string(),
        DisplayName: z.string().nullable(),
        MimeType: z.string(),
        Size: z.number(),
        Extension: z.string(),
        PublicUrl: z.string().url().nullable(),
        CreatedAt: zLuxonDateTime({ iso: true }),
        User: AppUserLinkSchema,
        LanguageCode: LanguageCodeSchema.nullable(),
    })
    .and(
        z
            .object({
                Url: z.string().url(),
                DeletedAt: z.null(),
            })
            .or(
                z.object({
                    Url: z.null(),
                    DeletedAt: zLuxonDateTime({ iso: true }),
                })
            )
    )
    .transform((val) => {
        return {
            ...val,
            DisplayName: val.DisplayName == null ? val.Name : val.DisplayName,
        };
    });

export const ChangeNameFileFormDataSchema = z.object({
    DisplayName: z.string(),
});

export const UploadFileFormDataSchema = z.object({
    DisplayName: z.string().optional(),
    LanguageCode: LanguageCodeSchema.optional(),
    File: zFile(),
});

export const RenameFileFormDataSchema = z.object({
    DisplayName: z.string(),
});

export const MoveFileFormDataSchema = z.object({
    FolderId: z.number(),
});

export type AppFileInfo = z.infer<typeof AppFileInfoSchema>;

export type UploadFileFormData = z.infer<typeof UploadFileFormDataSchema>;
export type RenameFileFormData = z.infer<typeof RenameFileFormDataSchema>;
export type MoveFileFormData = z.infer<typeof MoveFileFormDataSchema>;
export type ChangeNameFileFormData = z.infer<typeof ChangeNameFileFormDataSchema>;
