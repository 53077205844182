<script lang="ts" setup>
import { AppNavigationItemProps } from './_types';

const props = defineProps<AppNavigationItemProps>();

const route = useRoute();
const isActiveRoute = computed(() => route.name?.toString().startsWith(props.nav.to.name?.toString() ?? ''));
const canShow = computed(() => props.nav.can == null || props.nav.can());
</script>

<template>
    <app-header-navigation-hover-btn v-if="canShow && nav.children?.length" :label="$t(nav.title)">
        <q-list dense style="min-width: 100px">
            <q-item
                v-for="chNav in nav.children"
                :key="chNav.title"
                clickable
                active-class="text-bold text-primary"
                :to="chNav.to"
            >
                <q-item-section>
                    {{ $t(chNav.title) }}
                </q-item-section>
            </q-item>
        </q-list>
    </app-header-navigation-hover-btn>
    <flat-btn
        v-else-if="canShow && nav.icon"
        :icon="nav.icon"
        :text-color="isActiveRoute ? 'primary' : 'on-background-1'"
        :active-color="!isActiveRoute ? 'primary' : 'on-background-1'"
        :to="nav.to"
        dense
        class="q-mr-md-md"
    />
    <flat-btn
        v-else-if="canShow"
        :label="$t(nav.title)"
        :text-color="isActiveRoute ? 'primary' : 'on-background-1'"
        :active-color="!isActiveRoute ? 'primary' : 'on-background-1'"
        :to="nav.to"
    />
</template>
