import { CertificateDetail } from '~/api/certificates/_types';

export const useCertificateDetailStore = defineStore('certificate', () => {
    const activeCertificate = ref<CertificateDetail | null>(null);

    const reset = () => {
        activeCertificate.value = null;
    };

    return {
        activeCertificate,
        reset,
    };
});
