import { createI18n, PluralizationRule, PluralizationRulesMap } from 'vue-i18n';
import config from '~/plugins/config';
import cs from './locales/cs.json';
import en from './locales/en.json';
import enValidation from './locales/validation/zod.en.json';
import csValidation from './locales/validation/zod.cs.json';

const _createI18n = () => {
    const { locale, fallbackLocale } = config.app;

    const createPluralizationMap = (): PluralizationRulesMap => ({
        cs: (choice: number, choicesLength: number, orgRule?: PluralizationRule): number => {
            if (choice === 0) return choicesLength === 4 ? 0 : choicesLength - 1;
            if (choice === 1) return choicesLength - 3;
            if (choice > 1 && choice <= 4) return choicesLength - 2;
            if (choice > 4) return choicesLength - 1;

            return orgRule ? orgRule(choice, choicesLength) : 0;
        },
    });

    return createI18n({
        legacy: false,
        warnHtmlInMessage: false,
        globalInjection: true,
        locale,
        fallbackLocale,
        fallbackWarn: false,
        missingWarn: false,
        pluralRules: createPluralizationMap(),
        messages: {
            cs: {
                ...cs,
                zod: csValidation,
            },
            en: {
                ...en,
                zod: enValidation,
            },
        },
    });
};

export default _createI18n;
