<script lang="ts" setup>
import { useAppStore } from '~/stores/app';

const appStore = useAppStore();
</script>

<template>
    <flat-btn
        class="q-px-none"
        flat
        dense
        :tooltip="$t('generic.tooltips.menu')"
        aria-label="menu"
        icon="las la-bars"
        active-color="primary"
        text-color="on-background"
        @click="appStore.toggleLeftNavigation()"
    />
</template>
