import apiClient from '~/plugins/apiClient/client';
import { finishLogout } from '~/services/auth/utils';

// > Singleton instance
const apiClientInstance = apiClient('/', { notifyOnError: true });
export const weatherApiClientInstance = apiClient('/', {
    notifyOnError: true,
    public: true,
    axios: {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
        },
    },
    onAuthErrorFn: finishLogout,
});

export default apiClientInstance;
