export enum InstitututionState {
    InRegistration = 'InRegistration',
    Active = 'Active',
    Inactive = 'Inactive',
}

export enum InstitututionType {
    ElementarySchool = 'ElementarySchool',
    HighSchool = 'HighSchool',
    College = 'College',
    EducationAgency = 'EducationAgency',
}

export enum ProgramState {
    WaitingForDocuments = 'WaitingForDocuments',
    SentForCertification = 'SentForCertification',
    Certified = 'Certified',
    CertificationRefused = 'CertificationRefused',
    Inactive = 'Inactive',
}

export enum OrderState {
    Created = 'Created',
    AwaitingPayment = 'AwaitingPayment',
    Paid = 'Paid',
    Pending = 'Pending',
    Processing = 'Processing',
    Completed = 'Completed',
}

export enum OrderPaymentType {
    Invoice = 'Invoice',
    PaymentGateway = 'PaymentGateway',
}

export enum OrderType {
    Institution = 'Institution',
    CertOfSpecialist = 'CertOfSpecialist',
    CertForLecturers = 'CertForLecturers',
}

export enum CertificateState {
    AwaitingPayment = 'AwaitingPayment',
    Processing = 'Processing',
    Completed = 'Completed',
    InQueue = 'InQueue',
}

export enum CetificateType {
    Ies = 'IES',
    Ici = 'ICI',
    CertForLecturers = 'CertForLecturers',
    CertOfSpecialist = 'CertOfSpecialist',
}

export enum FilterOperator {
    Eq = 'eq',
    Neq = 'neq',
    Gt = 'gt',
    Gte = 'gte',
    Lt = 'lt',
    Lte = 'lte',
    Like = 'like',
}

export const isFilterOperator = (op?: string | null): op is FilterOperator => {
    if (op == null) return false;
    return Object.values(FilterOperator).find((x) => x.toString() === op) != null;
};
