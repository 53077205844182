import { AppFileInfo } from '~/api/files/_types';
import { OrderInfo, OrderLink, OrderStudentFormData, OrderStudentInfo } from '~/api/orders/_types';
import { InformationCardItemConfig } from '~/components/base/cards/_types';
import { FilterCardColumnConfig, FilterDataType } from '~/components/base/filters/_types';
import { AppTableColumnConfig } from '~/components/base/tables/_types';
import { EMPTY_DATA_PLACEHOLDER, REQUIRED_LANG } from '~/constants/_constants';
import { t } from '~/plugins/i18n';
import { Locale } from '~/plugins/i18n/_types';

export const getOrdersOverviewColumnConfig = (canSort: boolean): AppTableColumnConfig[] => [
    {
        name: 'Id',
        label: '',
        translatedLabel: { key: 'orders.overview.columns.Id' },
        field: 'Id',
        align: 'left',
        sortable: canSort,
    },
    {
        name: 'InstitutionName',
        label: '',
        translatedLabel: { key: 'orders.overview.columns.InstitutionName' },
        field: (val: OrderInfo) => val.Institution?.Name,
        align: 'left',
    },
    {
        name: 'ProgramName',
        label: '',
        translatedLabel: { key: 'orders.overview.columns.ProgramName' },
        field: (val: OrderInfo) => val.Program?.Name,
        align: 'left',
    },
    {
        name: 'FinishedAt',
        label: '',
        translatedLabel: { key: 'orders.overview.columns.FinishedAt.eq' },
        field: (val: OrderInfo) => val.FinishedAt.toLocaleString(),
        align: 'center',
        sortable: canSort,
    },
    {
        name: 'State',
        label: '',
        translatedLabel: { key: 'orders.overview.columns.State' },
        field: 'State',
        align: 'left',
        sortable: canSort,
    },
    {
        name: 'Actions',
        label: '',
        field: '',
        align: 'right',
    },
];

export const getOrdersOverviewFilterColumnConfig = (): FilterCardColumnConfig[] => [
    {
        dataType: FilterDataType.Number,
        mode: 'equals',
        key: 'Id',
        label: 'orders.overview.columns.Id',
        additionalFilter: false,
    },
    {
        dataType: FilterDataType.Date,
        mode: 'range',
        key: 'FinishedAt',
        labelFrom: 'orders.overview.columns.FinishedAt.from',
        labelTo: 'orders.overview.columns.FinishedAt.to',
        additionalFilter: false,
    },
    {
        dataType: FilterDataType.Enum,
        key: 'InstitutionId',
        label: 'orders.overview.columns.InstitutionName',
        additionalFilter: false,
        multiple: true,
        autocomplete: true,
        optionsKey: 'DisplayId',
        optionsLabelKey: 'Name',
    },
    {
        dataType: FilterDataType.Enum,
        key: 'ProgramId',
        label: 'orders.overview.columns.ProgramName',
        additionalFilter: false,
        multiple: true,
        autocomplete: true,
        optionsKey: 'DisplayId',
        optionsLabelKey: 'Name',
    },
    {
        dataType: FilterDataType.Enum,
        key: 'State',
        label: 'orders.overview.columns.State',
        additionalFilter: false,
        multiple: true,
    },
];

export const getOrderStudentStudentsFormColumnConfig = (): AppTableColumnConfig[] => [
    {
        name: 'Index',
        label: '',
        field: '',
        align: 'left',
    },
    {
        name: 'DegreeBefore',
        label: '',
        translatedLabel: { key: 'orders.form.columns.DegreeBefore' },
        field: '',
        align: 'left',
    },
    {
        name: 'FirstName',
        label: '',
        translatedLabel: { key: 'orders.form.columns.FirstName' },
        field: '',
        align: 'left',
    },
    {
        name: 'LastName',
        label: '',
        translatedLabel: { key: 'orders.form.columns.LastName' },
        field: '',
        align: 'left',
    },
    {
        name: 'DegreeAfter',
        label: '',
        translatedLabel: { key: 'orders.form.columns.DegreeAfter' },
        field: '',
        align: 'left',
    },
    {
        name: 'Birthday',
        label: '',
        translatedLabel: { key: 'orders.form.columns.Birthday' },
        field: '',
        align: 'left',
    },
    {
        name: 'Email',
        label: '',
        translatedLabel: { key: 'orders.form.columns.Email' },
        field: '',
        align: 'left',
    },
];

export const getOrderStudentsSummaryFormColumnConfig = (): AppTableColumnConfig[] => [
    {
        name: 'DegreeBefore',
        label: '',
        translatedLabel: { key: 'orders.form.columns.DegreeBefore' },
        field: (val: OrderStudentFormData) => (val.Data == null ? null : val.Data[REQUIRED_LANG]?.DegreeBefore),
        align: 'left',
    },
    {
        name: 'FirstName',
        label: '',
        translatedLabel: { key: 'orders.form.columns.FirstName' },
        field: (val: OrderStudentFormData) => (val.Data == null ? null : val.Data[REQUIRED_LANG]?.FirstName),
        align: 'left',
    },
    {
        name: 'LastName',
        label: '',
        translatedLabel: { key: 'orders.form.columns.LastName' },
        field: (val: OrderStudentFormData) => (val.Data == null ? null : val.Data[REQUIRED_LANG]?.LastName),
        align: 'left',
    },
    {
        name: 'DegreeAfter',
        label: '',
        translatedLabel: { key: 'orders.form.columns.DegreeAfter' },
        field: (val: OrderStudentFormData) => (val.Data == null ? null : val.Data[REQUIRED_LANG]?.DegreeAfter),
        align: 'left',
    },
    {
        name: 'Birthday',
        label: '',
        translatedLabel: { key: 'orders.form.columns.Birthday' },
        field: (val: OrderStudentFormData) => val.Birthday?.toLocaleString(),
        align: 'left',
    },
    {
        name: 'Email',
        label: '',
        translatedLabel: { key: 'orders.form.columns.Email' },
        field: 'Email',
        align: 'left',
    },
    {
        name: 'Languages',
        label: '',
        translatedLabel: { key: 'orders.form.columns.Languages' },
        field: (val: OrderStudentFormData) => val.Languages?.map((x) => x?.toUpperCase()).join(', '),
        align: 'left',
    },
    {
        name: 'LanguagesCount',
        label: '',
        translatedLabel: { key: 'orders.form.columns.LanguagesCount' },
        field: (val: OrderStudentFormData) => val.Languages?.length,
        align: 'center',
    },
];

export const getOrderStudentsDetailColumnConfig = (): AppTableColumnConfig[] => [
    {
        name: 'DegreeBefore',
        label: '',
        translatedLabel: { key: 'orders.detail.columns.DegreeBefore' },
        field: 'DegreeBefore',
        align: 'left',
    },
    {
        name: 'FirstName',
        label: '',
        translatedLabel: { key: 'orders.detail.columns.FirstName' },
        field: 'FirstName',
        align: 'left',
    },
    {
        name: 'LastName',
        label: '',
        translatedLabel: { key: 'orders.detail.columns.LastName' },
        field: 'LastName',
        align: 'left',
    },
    {
        name: 'DegreeAfter',
        label: '',
        translatedLabel: { key: 'orders.detail.columns.DegreeAfter' },
        field: 'DegreeAfter',
        align: 'left',
    },
    {
        name: 'Birthday',
        label: '',
        translatedLabel: { key: 'orders.detail.columns.Birthday' },
        field: (val: OrderStudentInfo) => val.Birthday?.toLocaleString(),
        align: 'left',
    },
    {
        name: 'Email',
        label: '',
        translatedLabel: { key: 'orders.detail.columns.Email' },
        field: 'Email',
        align: 'left',
    },
    {
        name: 'Languages',
        label: '',
        translatedLabel: { key: 'orders.detail.columns.Languages' },
        field: (val: OrderStudentInfo) => val.Languages?.map((x) => x?.toUpperCase()).join(', '),
        align: 'left',
    },
    {
        name: 'LanguagesCount',
        label: '',
        translatedLabel: { key: 'orders.detail.columns.LanguagesCount' },
        field: (val: OrderStudentInfo) => val.Languages?.length,
        align: 'center',
    },
    {
        name: 'Actions',
        label: '',
        field: '',
        align: 'right',
    },
];

export const getOrderDetailFilesColumnConfig = (): AppTableColumnConfig[] => [
    {
        name: 'DisplayName',
        label: '',
        translatedLabel: { key: 'orders.detail.files.columns.DisplayName' },
        field: 'DisplayName',
        align: 'left',
    },
    {
        name: 'Extension',
        label: '',
        translatedLabel: { key: 'orders.detail.files.columns.Extension' },
        field: 'Extension',
        align: 'left',
    },
    {
        name: 'CreatedAt',
        label: '',
        translatedLabel: { key: 'orders.detail.files.columns.CreatedAt' },
        field: (row: AppFileInfo) => row.CreatedAt.toLocaleString(),
        align: 'left',
    },
    {
        name: 'User',
        label: '',
        translatedLabel: { key: 'orders.detail.files.columns.User' },
        field: (row: AppFileInfo) => row.User.FullName,
        align: 'left',
    },
    {
        name: 'Actions',
        label: '',
        field: '',
        align: 'right',
    },
];

export const getOrderInfoCardConfig = (order: OrderLink, locale: Locale): InformationCardItemConfig[] => [
    {
        label: t('orders.detail.labels.PaymentType'),
        value: t(`enums.orderPaymentType.${order.PaymentType}`),
        position: {
            xs: { row: 0, col: 0 },
            sm: { row: 0, col: 0 },
            md: { row: 0, col: 0 },
        },
    },
    {
        label: t('orders.detail.labels.Price'),
        value:
            order.PriceInvoice?.toLocaleString(locale, {
                style: 'currency',
                currency: order.Currency?.toUpperCase(),
            }) ?? EMPTY_DATA_PLACEHOLDER,
        position: {
            xs: { row: 1, col: 0 },
            sm: { row: 0, col: 1 },
            md: { row: 0, col: 1 },
        },
    },
    {
        label: t('orders.detail.labels.RecipientEmail'),
        value: order.RecipientEmail ?? EMPTY_DATA_PLACEHOLDER,
        position: {
            xs: { row: 2, col: 0 },
            sm: { row: 1, col: 0 },
            md: { row: 0, col: 2 },
        },
    },
    {
        label: t('orders.detail.labels.PublishAt'),
        value: order.PublishAt.toLocaleString(),
        position: {
            xs: { row: 3, col: 0 },
            sm: { row: 1, col: 1 },
            md: { row: 1, col: 0 },
        },
    },
    {
        label: t('orders.detail.labels.FinishedAt'),
        value: order.FinishedAt.toLocaleString(),
        position: {
            xs: { row: 4, col: 0 },
            sm: { row: 2, col: 0 },
            md: { row: 1, col: 1 },
        },
    },
    {
        label: t('orders.detail.labels.State'),
        value: t(`enums.orderState.${order.State}`),
        valueClass: `text-bold a-text-22 enum-text-orderState--${order.State}`,
        position: {
            xs: { row: 5, col: 0 },
            sm: { row: 2, col: 1 },
            md: { row: 1, col: 2 },
        },
    },
];
