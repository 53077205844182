import { omit } from 'lodash-es';
import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import type {
    ApiHandlerWithArgs,
    ApiHandlerWithData,
    ApiHandlerWithDataParams,
    ApiResponse,
    ApiResponseContext,
} from '~/plugins/apiClient/_types';
import { transformZodSchemaFn } from '~/plugins/zod/utils';
import { transformFetchListOptionsToQuery } from '~/api/_transformers';
import { type FetchListOptions, PaginationMetaSchema } from '~/api/_types';
import {
    type FetchEnumProgramsReponse,
    FetchEnumProgramsReponseSchema,
    type FetchProgramNotesReponse,
    FetchProgramNotesReponseSchema,
    type FetchProgramsReponse,
    FetchProgramsReponseSchema,
    type ProgramAddFilesFormData,
    type ProgramChangeStateFormData,
    type ProgramDetail,
    ProgramDetailSchema,
    type ProgramFormData,
    type ProgramNoteFormData,
    type ProgramNoteInfo,
    type FetchProgramRevisionInfoResponse,
    FetchProgramRevisionInfoResponseSchema,
    type ProgramInfo,
    ProgramNoteInfoSchema,
    ProgramRevisionFormData,
} from './_types';
import { API_DATE_FORMAT } from '~/constants/_constants';
import { CreateProgramRelatedInstitutionInfo } from '../institutions/_types';

const apiEndpointPrefix = 'programs';

export const fetchProgramsRequest: ApiHandlerWithArgs<
    FetchListOptions,
    ApiResponseContext<FetchProgramsReponse | null>
> = async (opt) => {
    const url = formatApiUrl(apiEndpointPrefix);
    return api.get(url, {
        transform: transformZodSchemaFn(FetchProgramsReponseSchema, url),
        transformMeta: transformZodSchemaFn(PaginationMetaSchema, url),
        params: transformFetchListOptionsToQuery(opt),
    });
};

export const fetchEnumProgramsRequest: ApiHandlerWithArgs<
    FetchListOptions,
    ApiResponseContext<FetchEnumProgramsReponse | null>
> = async (opt) => {
    const url = formatApiUrl(apiEndpointPrefix, 'enum');
    return api.get(url, {
        transform: transformZodSchemaFn(FetchEnumProgramsReponseSchema, url),
        transformMeta: transformZodSchemaFn(PaginationMetaSchema, url),
        params: transformFetchListOptionsToQuery(opt),
    });
};

export const fetchProgramNotesRequest: ApiHandlerWithData<
    { programDisplayId: number; opt?: FetchListOptions },
    ApiResponseContext<FetchProgramNotesReponse | null>
> = async ({ programDisplayId, opt }) => {
    const url = formatApiUrl(apiEndpointPrefix, `${programDisplayId}/notes`);
    return api.get(url, {
        transform: transformZodSchemaFn(FetchProgramNotesReponseSchema, url),
        transformMeta: transformZodSchemaFn(PaginationMetaSchema, url),
        params: transformFetchListOptionsToQuery(opt),
    });
};

export const fetchProgramDetailRequest: ApiHandlerWithData<number, ApiResponseContext<ProgramDetail>> = async (
    displayId
) => {
    const url = formatApiUrl(apiEndpointPrefix, displayId);
    return api.get(url, { transform: transformZodSchemaFn(ProgramDetailSchema, url) });
};

export const fetchProgramRevisionDetailRequest: ApiHandlerWithData<number, ApiResponseContext<ProgramDetail>> = async (
    id
) => {
    const url = formatApiUrl(apiEndpointPrefix, `revisions/${id}`);
    return api.get(url, { transform: transformZodSchemaFn(ProgramDetailSchema, url) });
};

export const postProgramRequest: ApiHandlerWithData<
    { institutionDisplayId: number; data: ProgramFormData },
    ApiResponseContext<ProgramDetail | null, ProgramFormData>
> = async ({ institutionDisplayId, data }) => {
    const url = formatApiUrl('institutions', `${institutionDisplayId}/programs`);
    return api.post(url, transformProgramFormData(data), {
        transform: transformZodSchemaFn(ProgramDetailSchema, url),
    });
};

export const putProgramRequest: ApiHandlerWithData<
    { displayId: number; data: ProgramFormData },
    ApiResponseContext<ProgramDetail | null, ProgramFormData>
> = async ({ displayId, data }) => {
    const url = formatApiUrl(apiEndpointPrefix, displayId);
    return api.put(url, transformProgramFormData(data), {
        transform: transformZodSchemaFn(ProgramDetailSchema, url),
    });
};

export const putProgramRevisionRequest = ({
    id,
    data,
}: ApiHandlerWithDataParams<{
    id: number;
    data: ProgramRevisionFormData;
}>) => {
    const url = formatApiUrl(apiEndpointPrefix, `revisions/${id}`);
    return api.put<ProgramDetail | null, ProgramFormData>(url, transformProgramRevisionFormData(data), {
        transform: transformZodSchemaFn(ProgramDetailSchema, url),
    });
};

export const deleteProgramRevisionRequest = (id: number): Promise<ApiResponse<ProgramInfo, { id?: string } | null>> => {
    const url = formatApiUrl(apiEndpointPrefix, `revisions/${id}`);
    return api.delete(url, {
        transform: transformZodSchemaFn(ProgramDetailSchema, url),
    });
};

export const postProgramRevisionRequest = ({
    displayId,
    data,
}: ApiHandlerWithDataParams<{
    displayId: number;
    data: ProgramRevisionFormData;
}>) => {
    const url = formatApiUrl(apiEndpointPrefix, `${displayId}/revisions`);
    return api.post<ProgramDetail | null, ProgramFormData>(url, transformProgramRevisionFormData(data), {
        transform: transformZodSchemaFn(ProgramDetailSchema, url),
    });
};

export const patchProgramChangeStateRequest: ApiHandlerWithData<
    { displayId: number; data: ProgramChangeStateFormData },
    ApiResponseContext<ProgramDetail | null, ProgramChangeStateFormData>
> = async ({ displayId, data }) => {
    const url = formatApiUrl(apiEndpointPrefix, `${displayId}/state`);
    return api.patch(url, data, {
        transform: transformZodSchemaFn(ProgramDetailSchema, url),
    });
};

export const postProgramAddFilesRequest: ApiHandlerWithData<
    { displayId: number; data: ProgramAddFilesFormData },
    ApiResponseContext<ProgramDetail | null, ProgramAddFilesFormData>
> = async ({ displayId, data }) => {
    const url = formatApiUrl(apiEndpointPrefix, `${displayId}/files`);
    return api.post(url, data, {
        transform: transformZodSchemaFn(ProgramDetailSchema, url),
    });
};

export const postProgramNoteRequest: ApiHandlerWithData<
    { programDisplayId: number; data: ProgramNoteFormData },
    ApiResponseContext<ProgramNoteInfo | null, ProgramNoteFormData>
> = async ({ programDisplayId, data }) => {
    const url = formatApiUrl(apiEndpointPrefix, `${programDisplayId}/notes`);
    return api.post(url, data, {
        transform: transformZodSchemaFn(ProgramNoteInfoSchema, url),
    });
};

export const deleteProgramNoteRequest: ApiHandlerWithData<number, ApiResponseContext<null>> = async (id) => {
    const url = formatApiUrl('program-notes', id);
    return api.delete(url);
};

const transformProgramFormData = (data: ProgramFormData) => {
    return {
        ...omit(data, ['Institution', 'ValidFrom', 'ValidTo']),
        ValidFrom: data.ValidFrom?.toFormat(API_DATE_FORMAT) ?? null,
        ValidTo: data.ValidTo?.toFormat(API_DATE_FORMAT) ?? null,
    };
};

export const fetchProgramRevisionsRequest = ({ displayId, opt }: ApiHandlerWithDataParams<{ displayId: number }>) => {
    const url = formatApiUrl(apiEndpointPrefix, `${displayId}/revisions`);
    return api.get<FetchProgramRevisionInfoResponse>(url, {
        transform: transformZodSchemaFn(FetchProgramRevisionInfoResponseSchema, url),
        transformMeta: transformZodSchemaFn(PaginationMetaSchema, url),
        params: transformFetchListOptionsToQuery(opt),
    });
};

export const fetchProgramDateRelatedInstitutionRevisionsRequest = ({
    displayId,
    validFrom,
    opt,
}: ApiHandlerWithDataParams<{ displayId: number; validFrom: Date | null | undefined }>) => {
    const url = formatApiUrl(apiEndpointPrefix, `${displayId}/institution-revision/by-validity`);
    const query = {
        ...transformFetchListOptionsToQuery(opt),
        ...{ ValidFrom: validFrom },
    };

    return api.get<CreateProgramRelatedInstitutionInfo>(url, {
        transform: transformZodSchemaFn(CreateProgramRelatedInstitutionInfo, url),
        transformMeta: transformZodSchemaFn(PaginationMetaSchema, url),
        params: query,
    });
};

const transformProgramRevisionFormData = (data: ProgramRevisionFormData) => {
    return {
        ...data,
        ValidFrom: data.ValidFrom?.toFormat(API_DATE_FORMAT) ?? null,
        ValidTo: data.ValidTo?.toFormat(API_DATE_FORMAT) ?? null,
    };
};
