import { defineStore } from 'pinia';
import { ConfigDataInfo } from '~/api/config/_types';

export const useConfigStore = defineStore('config', () => {
    const configData = ref<ConfigDataInfo | null>(null);

    const setConfigData = (data: ConfigDataInfo | null) => {
        configData.value = data;
    };

    return {
        configData,
        setConfigData,
    };
});
