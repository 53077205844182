import { Notify } from 'quasar';
import { configureScope as sentryConfigureScope } from '@sentry/vue';
import { AuthLoginResponse } from '~/api/auth/_types';
import { useAuthStore } from '~/plugins/auth';
import { AuthUser, UserPreferences } from '~/plugins/auth/_types';
import localeStorage from '~/plugins/i18n/store';
import { t } from '~/plugins/i18n';

// @plachtova: this code cannot be in 'index' and must be in separate file because of CIC dependency
// we need to use this code in api client (for logout) therefore we separated api call and handling result to different files
// so we can call these methods in api client directly

export const finishLogout = async (silent: boolean = false) => {
    const authStore = useAuthStore();
    await authStore.logout();

    if (!silent) {
        Notify.create({
            group: 'loggedOut',
            type: 'positive',
            message: t('auth.notify.loggedOut'),
        });
    }

    setTimeout(async () => {
        executePostLogoutActions();
    }, 0);
};

export const finishLogin = async (response: AuthLoginResponse) => {
    const authStore = useAuthStore();
    authStore.setToken({
        ExpiresAt: response.ExpiresAt,
        Token: response.Token,
    });

    await authStore.finishLogin(response.User);

    setPreferencedLocale(response.User.Preferences);

    Notify.create({
        group: 'loggedIn',
        type: 'positive',
        message: t('auth.notify.loggedIn'),
    });

    executePostLoginActions(response.User);
};

export const finishAutologin = (user: AuthUser) => {
    const authStore = useAuthStore();
    authStore.setUser(user);

    setPreferencedLocale(user.Preferences);

    setTimeout(() => {
        executePostLoginActions(user);
    }, 0);
};

const executePostLoginActions = async (user: AuthUser) => {
    sentryConfigureScope((scope) => {
        scope.setUser({ id: user.Id, type: user.Type });
    });
};

const executePostLogoutActions = () => {
    sentryConfigureScope((scope) => {
        scope.setUser(null);
    });
};

const setPreferencedLocale = (preferences: UserPreferences | null) => {
    if (preferences && preferences.Locale) {
        localeStorage.activeLocale.value = preferences.Locale;
    }
};
