import { Config, EnvMode } from '~/plugins/config/_types';
import { debug } from '~/utils/debugUtils';
import { parseEnvValue, parseEnvValueOrFail } from '~/plugins/config/utils';
import { Locale } from '~/plugins/i18n/_types';

const {
    DEV,
    MODE,
    VITE_APP_VERSION,
    VITE_API_URL,
    VITE_DEFAULT_LOCALE,
    VITE_FALLBACK_LOCALE,
    VITE_AVAILABLE_LOCALES,
    VITE_SENTRY_DSN,
    VITE_SENTRY_ENABLED,
    VITE_SENTRY_AUTH_TOKEN,
    VITE_SENTRY_URL,
    VITE_SENTRY_ORG,
    VITE_SENTRY_PROJECT,
    VITE_SENTRY_RELEASE,
    VITE_DEV_LOGIN,
    VITE_DEV_PASSWORD,
} = import.meta.env;

const createConfig = () => {
    const config: Config = {
        app: {
            version: parseEnvValue<string>(VITE_APP_VERSION, '0.0.0'),
            locale: parseEnvValue<Locale>(VITE_DEFAULT_LOCALE, 'cs'),
            fallbackLocale: JSON.parse(parseEnvValue<string>(VITE_FALLBACK_LOCALE, '["cs"]')),
            availableLocales: JSON.parse(parseEnvValue<string>(VITE_AVAILABLE_LOCALES, '["cs"]')),
        },
        api: {
            baseURL: parseEnvValueOrFail<string>(VITE_API_URL),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Requested-With': 'XMLHttpRequest',
            },
        },
        env: {
            dev: DEV,
            mode: MODE as EnvMode,
        },
        storage: {
            data: {
                auth: {
                    key: 'auth_token',
                },
                locale: {
                    key: 'lang',
                },
            },
            type: 'local',
        },
        sentry: {
            enabled: JSON.parse(parseEnvValue<string>(VITE_SENTRY_ENABLED, 'false')),
            dsn: parseEnvValue<string>(VITE_SENTRY_DSN, ''),
            org: parseEnvValue<string>(VITE_SENTRY_ORG, ''),
            project: parseEnvValue<string>(VITE_SENTRY_PROJECT, ''),
            url: parseEnvValue<string>(VITE_SENTRY_URL, ''),
            authToken: parseEnvValue<string>(VITE_SENTRY_AUTH_TOKEN, ''),
            release: parseEnvValue<string>(VITE_SENTRY_RELEASE, ''),
        },
        dev: {
            login: DEV ? parseEnvValue<string>(VITE_DEV_LOGIN, '') : '',
            password: DEV ? parseEnvValue<string>(VITE_DEV_PASSWORD, '') : '',
        },
    };

    const isLocaleSupported = (locale: Locale): boolean => config.app.availableLocales.includes(locale);

    debug('[ConfigService]', config);

    return Object.freeze({
        ...config,
        isLocaleSupported,
    });
};

export default createConfig;
