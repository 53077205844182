import { Settings } from 'luxon';
import i18nInstance from '.';
import { Locale } from './_types';
import { changeQuasarLocale } from '~/plugins/quasar/_plugin';

export const changeLocale = (newLocale: Locale) => {
    changeI18nLocale(newLocale);
    changeQuasarLocale(newLocale);
    changeLuxonLocale(newLocale);
};

/**
 * Changes locale for i18n
 * @param newLocale
 */
const changeI18nLocale = (newLocale: Locale) => {
    i18nInstance.global.locale.value = newLocale;
};

/**
 * set default language for luxon (does not affect already created instances)
 * @param newLocale
 */
const changeLuxonLocale = (newLocale: Locale) => {
    Settings.defaultLocale = newLocale;
};
