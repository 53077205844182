import localeStorage from '~/plugins/i18n/store';
import { NavigationGuardWithThis, NavigationHookAfter, RouteLocationNormalized, START_LOCATION } from 'vue-router';
import { useAuthStore } from '~/plugins/auth';
import { tryAutoLogin } from '~/services/auth';
import { setPageTitle } from '~/utils/localeUtils';
import { useAppStore } from '~/stores/app';
import { AUTH_LOGIN_ROUTE, HOME_ROUTE } from './_constants';
import { goToAccessDeniedPage, goToNotFoundPage, isPublicRoute } from './utils';
import { fetchConfig } from '~/services/config';
import pageBefore from '~/pages/_routerBefore';
import pageAfter from '~/pages/_routerAfter';
import { RedirectType } from './_types';
import { getPageRedirects } from '~/pages/_redirects';
import routerInstance from '.';

export const beforeEach: NavigationGuardWithThis<undefined> = async (to, from, next) => {
    const authStore = useAuthStore();
    const { setIsRouting } = useAppStore();
    setIsRouting(true);

    // When the Router loads for the first time.
    // https://router.vuejs.org/api/#start-location
    if (from === START_LOCATION) {
        localeStorage.init();
        await Promise.allSettled([fetchConfig(), tryAutoLogin()]);
    }

    if (!isPublicRoute(<string>to.name) && !authStore.loggedIn) {
        await authStore.logout();
        return next({ name: AUTH_LOGIN_ROUTE, query: { to: <string>to.name, params: JSON.stringify(to.params) } });
    } else if (authStore.loggedIn && to.name === AUTH_LOGIN_ROUTE) {
        return next({ name: HOME_ROUTE });
    }

    if (to.name === AUTH_LOGIN_ROUTE) {
        authStore.setToken(null);
    }

    const redirect = await shouldRedirectPage(to);
    switch (redirect) {
        case RedirectType.AccessDenied: {
            // @plachtova: next can only push, but we want to replace the page
            next(false);
            return await goToAccessDeniedPage(routerInstance, to.fullPath);
        }
        case RedirectType.NotFound: {
            // @plachtova: next can only push, but we want to replace the page
            next(false);
            return await goToNotFoundPage(routerInstance, to.fullPath);
        }
    }

    if (await pageBefore.bind(undefined, to, from, next)()) return;

    to.meta.backlink = from;

    next();
};

export const afterEach: NavigationHookAfter = (to, from, failure) => {
    setPageTitle(to);

    pageAfter(to, from, failure);

    const { setIsRouting } = useAppStore();
    setIsRouting(false);
};

const shouldRedirectPage = async (to: RouteLocationNormalized): Promise<RedirectType> => {
    const pageRedirects = getPageRedirects();
    const shouldRedirectPageFn = pageRedirects[to.name?.toString() || ''];
    return (shouldRedirectPageFn != null && (await shouldRedirectPageFn(to))) || RedirectType.NoRedirect;
};
