<script lang="ts" setup>
import { useAppStore } from '~/stores/app';
import { PageBaseProps } from './_types';
const props = withDefaults(defineProps<PageBaseProps>(), {
    loading: false,
    showContentWhenLoading: true,
    padding: true,
    centerPage: true,
    showHeaderImage: false,
});
const { isRouting } = storeToRefs(useAppStore());

const isPageLoading = computed(() => props.loading || isRouting.value);
</script>

<template>
    <q-page class="column no-wrap">
        <div v-if="isPageLoading" class="flex flex-stretch absolute-full all-pointer-events light-dimmed z-top">
            <slot name="loading">
                <q-inner-loading :showing="isPageLoading" color="primary" />
            </slot>
        </div>

        <template v-if="!(showContentWhenLoading === false) || !isPageLoading">
            <div
                v-if="$slots.header"
                class="column col-shrink no-wrap"
                :class="{
                    'q-px-sm-md q-px-xs-sm q-py-md': padding,
                    'bg-surface': !showHeaderImage,
                    'page-header-image': showHeaderImage,
                }"
            >
                <div class="col" :class="[{ 'page-container': centerPage }, headerClass]">
                    <slot name="header" />
                </div>
            </div>
            <div class="column col-grow no-wrap" :class="{ 'q-px-sm-md q-px-xs-sm q-py-md': padding }">
                <div class="col" :class="[{ 'page-container': centerPage }, contentClass]">
                    <slot />
                </div>
            </div>
        </template>
    </q-page>
</template>
