<script lang="ts" setup></script>

<template>
    <q-header elevated class="bg-background text-on-background">
        <div class="q-px-sm-md q-px-xs-sm q-py-sm">
            <q-toolbar class="full-height row justify-start page-container q-pa-none">
                <app-drawer-navigation-btn class="lt-md" />
                <logo-img
                    class="gt-sm block"
                    clickable
                    style="object-position: left center; width: auto; height: 75px"
                />
                <q-space />
                <app-header-navigation />
                <app-user-navigation />
                <app-lang-switch />
            </q-toolbar>
        </div>
    </q-header>
</template>
