import { z } from 'zod';
import { zDbId, zGuid, zLuxonDateTime, zNonEmptyString } from '~/plugins/zod/utils';
import {
    AppUserLinkSchema,
    CurrencyCodeSchema,
    InstitutionLinkSchema,
    LanguageCodeSchema,
    ProgramLinkSchema,
    StudentDataSchema,
} from '~/api/links/_types';
import { API_DATE_FORMAT } from '~/constants/_constants';
import { OrderPaymentType, OrderState, OrderType } from '~/enums/_types';
import { AppFileInfoSchema } from '~/api/files/_types';

export enum OrderFormStep {
    Order = 'Order',
    Students = 'Students',
    Certificates = 'Certificates',
    Summary = 'Summary',
}

export const OrderLinkSchema = z.object({
    Id: zDbId(),
    FinishedAt: zLuxonDateTime({ format: API_DATE_FORMAT }),
    PublishAt: zLuxonDateTime({ format: API_DATE_FORMAT }),
    Type: z.nativeEnum(OrderType),
    State: z.nativeEnum(OrderState),
    PaymentType: z.nativeEnum(OrderPaymentType),
    RecipientEmail: z.string().nullable(),
    PriceInvoice: z.number().nullable(),
    Currency: CurrencyCodeSchema.nullable(),
});

export const OrderInfoSchema = z.object({
    Id: zDbId(),
    Institution: InstitutionLinkSchema.nullable(),
    Program: ProgramLinkSchema.nullable(),
    FinishedAt: zLuxonDateTime({ format: API_DATE_FORMAT }),
    State: z.nativeEnum(OrderState),
});

export const FetchOrdersResponseSchema = OrderInfoSchema.array();

export const OrderStudentInfoSchema = StudentDataSchema.and(
    z.object({
        User: AppUserLinkSchema.nullable(),
        CertificatesCount: z.number(),
        Languages: LanguageCodeSchema.array(),
    })
);

export const OrderLimitedInfoSchema = z.object({
    Id: zDbId(),
    FinishedAt: zLuxonDateTime({ format: API_DATE_FORMAT }),
    PublishAt: zLuxonDateTime({ format: API_DATE_FORMAT }),
    State: z.nativeEnum(OrderState),
});

export const OrderDetailSchema = OrderInfoSchema.and(
    z.object({
        CreatedAt: zLuxonDateTime({ iso: true }),
        CreatedBy: AppUserLinkSchema,
        UpdatedAt: zLuxonDateTime({ iso: true }).nullable(),
        UpdatedBy: AppUserLinkSchema.nullable(),
        PublishAt: zLuxonDateTime({ format: API_DATE_FORMAT }),
        Type: z.nativeEnum(OrderType),
        PaymentType: z.nativeEnum(OrderPaymentType),
        RecipientEmail: z.string().email().nullable(),
        PriceInvoice: z.number().nullable(),
        PriceCZK: z.number().nullable(),
        Currency: CurrencyCodeSchema.nullable(),
        CertificatesCount: z.number(),
        NextStates: z.array(z.nativeEnum(OrderState)),
        Students: OrderStudentInfoSchema.array(),
        Files: AppFileInfoSchema.array().default([]),
        Competences: z.string().nullable(),
        FieldOfActivity: z.record(LanguageCodeSchema, z.string()).nullable(),
    })
);

export const OrderStudentFormDataSchema = z.object({
    // only for FE
    _Uid: zGuid(),
    Email: z.string().email().nullish(),
    Birthday: zLuxonDateTime().nullish(),
    Languages: LanguageCodeSchema.array().min(1).default([]),
    Data: z.record(
        LanguageCodeSchema,
        z.object({
            DegreeBefore: z.string().nullish(),
            FirstName: zNonEmptyString(),
            LastName: zNonEmptyString(),
            DegreeAfter: z.string().nullish(),
        })
    ),
});

export const OrderFormDataSchema = z.object({
    FinishedAt: zLuxonDateTime(),
    PublishAt: zLuxonDateTime(),
    PaymentType: z.nativeEnum(OrderPaymentType),
    RecipientEmail: z.string().email(),
    InstitutionId: z.number(),
    ProgramId: z.number(),
    Competences: z.string(),
    Students: OrderStudentFormDataSchema.array().min(1).default([]),
});

export const OrderIciStudentFormDataSchema = z.object({
    Email: z.string().email(),
    Birthday: zLuxonDateTime(),
    DegreeBefore: z.string().optional(),
    FirstName: zNonEmptyString(),
    LastName: zNonEmptyString(),
    DegreeAfter: z.string().optional(),
});

export const OrderIciFormDataSchema = z.object({
    Type: z.literal(OrderType.CertForLecturers).or(z.literal(OrderType.CertOfSpecialist)),
    FinishedAt: zLuxonDateTime(),
    PublishAt: zLuxonDateTime(),
    FieldOfActivity: z.record(LanguageCodeSchema, z.string()),
    Price: z.number().min(0),
    Currency: CurrencyCodeSchema,
    Rating: z.string(),
    PaymentType: z.nativeEnum(OrderPaymentType),
    Student: OrderIciStudentFormDataSchema,
});

export const OrderChangePublishAtFormDataSchema = z.object({
    PublishAt: zLuxonDateTime(),
});

export const OrderChangeStateFormDataSchema = z.object({
    State: z.nativeEnum(OrderState),
    PriceInvoice: z.number().min(0).optional(),
    Currency: CurrencyCodeSchema.optional(),
    Files: z.string().array().optional(),
});

export type OrderLink = z.infer<typeof OrderLinkSchema>;
export type OrderInfo = z.infer<typeof OrderInfoSchema>;
export type OrderDetail = z.infer<typeof OrderDetailSchema>;
export type OrderStudentInfo = z.infer<typeof OrderStudentInfoSchema>;
export type FetchOrdersResponse = z.infer<typeof FetchOrdersResponseSchema>;
export type OrderStudentFormData = z.infer<typeof OrderStudentFormDataSchema>;
export type OrderFormData = z.infer<typeof OrderFormDataSchema>;
export type OrderIciFormData = z.infer<typeof OrderIciFormDataSchema>;
export type OrderIciStudentFormData = z.infer<typeof OrderIciStudentFormDataSchema>;
export type OrderChangePublishAtFormData = z.infer<typeof OrderChangePublishAtFormDataSchema>;
export type OrderChangeStateFormData = z.infer<typeof OrderChangeStateFormDataSchema>;
