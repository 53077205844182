import { defineStore } from 'pinia';
import { Screen } from 'quasar';

export const useAppStore = defineStore('app', () => {
    const isRouting = ref(false);

    const setIsRouting = (val: boolean) => {
        if (val) {
            if (Screen.lt.md) isLeftNavigationOpen.value = false;
        }

        isRouting.value = val;
    };

    const isLeftNavigationOpen = ref(false);
    const toggleLeftNavigation = () => (isLeftNavigationOpen.value = !isLeftNavigationOpen.value);

    return {
        isRouting,
        isLeftNavigationOpen,
        toggleLeftNavigation,
        setIsRouting,
    };
});
