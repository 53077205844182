import { AppUserInfo } from '~/api/users/_types';

export const useAppUserDetailStore = defineStore('app-user', () => {
    const activeUser = ref<AppUserInfo | null>(null);

    const reset = () => {
        activeUser.value = null;
    };

    return {
        activeUser,
        reset,
    };
});
