<script lang="ts" setup>
import { Locale } from '~/plugins/i18n/_types';
import { setPageTitle } from '~/utils/localeUtils';

const route = useRoute();
const { appLocales, activeLocale, setActiveLocale } = useAppLocale();

const changeActiveLocale = async (locale: Locale) => {
    await setActiveLocale(locale);
    setPageTitle(route);
};
</script>
<template>
    <div class="column q-ml-sm">
        <template v-for="(locale, index) in appLocales" :key="locale.value">
            <FlatBtn
                :label="locale.label"
                :text-color="activeLocale === locale.value ? 'primary' : 'secondary'"
                size="0.75rem"
                class="q-px-sm"
                dense
                @click="changeActiveLocale(locale.value)"
            />
            <q-separator v-if="index < appLocales.length - 1" />
        </template>
    </div>
</template>
