import { omit } from 'lodash-es';
import { ZodErrorMap, ZodIssueCode } from 'zod';
import { t } from '~/plugins/i18n';
import { isEmpty } from '~/utils/inputUtils';

export const localizedErrorMap: ZodErrorMap = (issue, ctx) => {
    const result: ReturnType<ZodErrorMap> = { message: '' };

    // @plachtova:
    // this takes priority therefore it cannot be modified with this error map
    // it does nto matter what we send in result, message will always be what is in 'issue.message'
    if (issue.message?.length) {
        return result;
    }

    // if data is empty it will override issue code specific messages
    if (isEmpty(ctx.data)) {
        result.message = t('zod.errors.invalid_received_empty');
        return result;
    }

    switch (issue.code) {
        case ZodIssueCode.custom: {
            const customMsg = issue.params?.message;
            if (isEmpty(customMsg)) break;

            result.message = t(customMsg, {
                ...omit(issue.params, ['message']),
            });
            break;
        }
        case ZodIssueCode.invalid_string: {
            if (typeof issue.validation === 'object' && 'startsWith' in issue.validation) {
                result.message = t(`zod.errors.${issue.code}.startsWith`, {
                    startsWith: issue.validation.startsWith,
                });
                break;
            }

            if (typeof issue.validation === 'object' && 'endsWith' in issue.validation) {
                result.message = t(`zod.errors.${issue.code}.endsWith`, {
                    endsWith: issue.validation.endsWith,
                });
                break;
            }

            result.message = t(`zod.errors.${issue.code}.${issue.validation}`, {
                validation: t(`zod.validations.${issue.validation}`),
            });
            break;
        }
        case ZodIssueCode.too_big:
        case ZodIssueCode.too_small: {
            const params =
                issue.code === ZodIssueCode.too_big ? { maximum: issue.maximum } : { minimum: issue.minimum };
            const val = issue.code === ZodIssueCode.too_big ? issue.maximum : issue.minimum;

            const tmp = issue.exact ? 'exact' : issue.inclusive ? 'inclusive' : 'not_inclusive';
            result.message = t(`zod.errors.${issue.code}.${issue.type}.${tmp}`, params, val as number);
            break;
        }
    }

    if (!result.message) {
        result.message = t(`zod.errors.${issue.code}`, {
            ...omit(issue, ['code', 'message', 'path']),
        });
    }

    return result;
};
