<script lang="ts" setup>
import { getCssVar, QBtnSlots } from 'quasar';
import type { ContainedBtnProps } from './_types';

const props = withDefaults(defineProps<ContainedBtnProps>(), {
    color: 'primary',
    textColor: 'on-primary',
    square: true,
});
const color = computed(() => getCssVar(props.color || '') || props.color);
const textColor = computed(() => getCssVar(props.textColor || '') || props.textColor);
</script>
<template>
    <q-btn
        v-bind="props"
        class="app-btn app-btn-contained app-btn--no-q-hover text-bold"
        :class="{ 'app-btn--small-icon': smallIcons }"
        unelevated
        :color="color"
        :text-color="textColor"
        :style="{ '--app-btn-contained--text-color': textColor, '--app-btn-contained--color': color }"
        no-caps
        :ripple="false"
    >
        <q-tooltip v-if="tooltip" :square="square">{{ tooltip }}</q-tooltip>

        <template
            v-for="(_, slotName) in $slots as Readonly<QBtnSlots>"
            #[slotName]="// @ts-ignore
        slotProps"
        >
            <slot :name="slotName" v-bind="slotProps || {}" />
        </template>
    </q-btn>
</template>
