<script lang="ts" setup>
import { useAppStore } from '~/stores/app';
import { AppNavigationItemProps } from './_types';

const props = defineProps<AppNavigationItemProps>();

const { isLeftNavigationOpen } = storeToRefs(useAppStore());
const route = useRoute();

const expanded = ref(false);
const canShow = computed(() => props.nav.can == null || props.nav.can());

watch([isLeftNavigationOpen], () => {
    expanded.value = !isLeftNavigationOpen.value
        ? false
        : !!route.name?.toString().startsWith(props.nav.to.name?.toString() ?? '');
});
</script>
<template>
    <q-expansion-item
        v-if="canShow && nav.children?.length"
        v-model="expanded"
        :label="$t(nav.title)"
        header-class="text-bold"
        expand-icon="las la-angle-down"
    >
        <q-list dense style="min-width: 100px">
            <q-item v-for="c in nav.children" :key="c.title" clickable active-class="text-bold text-primary" :to="c.to">
                <q-item-section>
                    {{ $t(c.title) }}
                </q-item-section>
            </q-item>
        </q-list>
    </q-expansion-item>
    <q-item v-else-if="canShow" class="text-bold" clickable :to="nav.to">
        <q-item-section>
            {{ $t(nav.title) }}
        </q-item-section>
    </q-item>
</template>
