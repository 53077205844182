export const getFullName = (user: {
    FirstName: string;
    LastName: string;
    DegreeBefore?: string | null;
    DegreeAfter?: string | null;
}): string => {
    let fullName = [user.DegreeBefore || '', user.FirstName, user.LastName].join(' ');

    if (user.DegreeAfter) {
        fullName = `${fullName}, ${user.DegreeAfter}`;
    }

    return fullName.trim();
};
