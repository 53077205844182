import router from '~/plugins/router';
import { FetchDetailOptions, FetchListOptions, PaginationMeta } from '~/api/_types';
import {
    deleteAnnouncementRequest,
    fetchAnnouncementsRequest,
    fetchDashboardAnnouncementsRequest,
    postAnnouncementRequest,
    putAnnouncementRequest,
} from '~/api/announcements';
import {
    AnnouncementFormData,
    AnnouncementInfo,
    AnnouncementsArrayDashboardInfo,
    AnnouncementsArrayInfo,
} from '~/api/announcements/_types';
import {
    ApiResponseContext,
    ApiSimpleResponseContext,
    FormHandler,
    FormHandlerWithArgs,
} from '~/plugins/apiClient/_types';
import { fetchAndStoreData, updateAndStoreFormData } from '~/services/utils';
import { FilterOperator } from '~/enums/_types';
import { useAnnouncementStore } from '~/stores/announcement';
import { HttpStatusCode } from 'axios';

export const fetchDashboardAnnouncements = async (): Promise<
    ApiSimpleResponseContext<AnnouncementsArrayDashboardInfo | null>
> => {
    return await fetchDashboardAnnouncementsRequest();
};

export const fetchAnnouncements = async (
    opt?: FetchListOptions
): Promise<ApiResponseContext<AnnouncementsArrayInfo | null, null, PaginationMeta | null>> => {
    return await fetchAnnouncementsRequest(opt);
};

export const fetchAnnouncementDetail = async (
    id: number,
    opt?: FetchDetailOptions
): Promise<ApiSimpleResponseContext<AnnouncementInfo | null>> => {
    return await fetchAndStoreData(
        router,
        'Id',
        id,
        getActiveAnnouncement,
        async () => {
            const { status, isSuccess, data } = await fetchAnnouncementsRequest({
                filter: [{ column: 'Id', op: FilterOperator.Eq, val: id }],
            });

            const res = {
                status: !isSuccess || (data && data.length) ? status : HttpStatusCode.NotFound,
                isSuccess: !isSuccess || (data && data.length) ? isSuccess : false,
                data: data && data.length ? data[0] : null,
            };

            return res;
        },
        opt
    );
};

export const createAnnouncement: FormHandler<
    AnnouncementFormData,
    ApiSimpleResponseContext<AnnouncementInfo | null>
> = async (values, ctx) => {
    return await updateAndStoreFormData(ctx, getActiveAnnouncement, async () => {
        return await postAnnouncementRequest(values);
    });
};

export const updateAnnouncement: FormHandlerWithArgs<
    number,
    AnnouncementFormData,
    ApiSimpleResponseContext<AnnouncementInfo | null>
> = async (id, values, ctx) => {
    return await updateAndStoreFormData(ctx, getActiveAnnouncement, async () => {
        return await putAnnouncementRequest({ id, data: values });
    });
};

export const deleteAnnouncement = async (id: number): Promise<ApiSimpleResponseContext<AnnouncementInfo | null>> => {
    return await deleteAnnouncementRequest(id);
};

const getActiveAnnouncement = () => storeToRefs(useAnnouncementStore()).activeAnnouncement;
