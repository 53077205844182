import { isEmpty } from 'lodash-es';

/**
 * Remove a trailing slash from URL
 * @param url
 */
export const normalizeUrl = (url: string): string => {
    return url.endsWith('/') ? url.slice(0, -1) : url;
};

/**
 * Check if the given data has files.
 *
 * @param data
 */
export const hasFiles = (
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    data: File | Blob | FileList | Record<string, any>
): boolean => {
    return (
        data instanceof File ||
        data instanceof Blob ||
        data instanceof FileList ||
        (typeof data === 'object' &&
            data !== null &&
            Object.values(data).find((value) => hasFiles(value)) !== undefined)
    );
};

/**
 * utils method for exhaustive switch pattern
 * https://medium.com/technogise/type-safe-and-exhaustive-switch-statements-aka-pattern-matching-in-typescript-e3febd433a7a
 * @param enumValue
 */
export const exhaustiveTypeguard = (_: never): never => {
    throw new Error('Should never get here!');
};

/**
 * Convert dot-notated data to a nested object
 * @param data
 * https://stackoverflow.com/questions/7793811/convert-javascript-dot-notation-object-to-nested-object
 */
export const convertDotObjectToNested = (data: Record<string, string | object> | object) => {
    return Object.entries(data).reduce(
        (a, [p, v]) => (
            p.split('.').reduce((b, k, i, r) => {
                // @ts-ignore
                return (b[k] ??= i === r.length - 1 ? v : {});
            }, a),
            a
        ),
        {}
    );
};

export const getAcronym = (val: string): string => {
    return val
        .split(' ')
        .filter((x) => !isEmpty(x))
        .map((x) => x[0])
        .join('');
};

/**
 * Alias method for 'Object.keys' method that returns typed keys instead of string[]
 * It is not exactly typesafe though because at runtime there might be additional keys.
 */
export const getTypedKeys = Object.keys as <T extends object>(yourObject: T) => Array<keyof T>;

export const getOutsideUrl = (url: string) => {
    return url.startsWith('http') ? url : `https://${url}`;
};
