import { z } from 'zod';
import { FilterOperator } from '~/enums/_types';
import { zNullishDefault } from '~/plugins/zod/utils';

export const PaginationMetaSchema = z.object({
    Limit: zNullishDefault(z.number(), -1),
    Offset: zNullishDefault(z.number(), 0),
    Total: zNullishDefault(z.number(), 0),
});

export type PaginationMeta = z.infer<typeof PaginationMetaSchema>;

export interface FetchListOptions {
    pagination?: FetchListPaginationOptions | null;
    sort?: FetchListSortOptions | null;
    search?: string | null;
    filter?: FetchListFilterOptions[] | null;
}

export interface FetchListPaginationOptions {
    offset: number;
    limit: number;
}
export interface FetchListSortOptions {
    column: string;
    descending: boolean;
}

export interface FetchListFilterOptions {
    column: string;
    op: FilterOperator;
    val: unknown;
}

export type FetchDetailOptions = CacheOptions &
    RedirectOptions & {
        routeNamePrefix?: string;
    };

export interface CacheOptions {
    noStore?: boolean;
    force?: boolean;
}

export interface RedirectOptions {
    redirectIfNotFound?: boolean;
    redirectIfAccessDenied?: boolean;
}

export type FormMode = 'create' | 'edit';
