<script lang="ts" setup>
import { AnnouncementsCarouselProps } from './_types';

const props = withDefaults(defineProps<AnnouncementsCarouselProps>(), {
    autoplay: 30000,
    height: '100px',
});

const { activeLocale } = useAppLocale();

const { data, request } = useDashboardAnnouncements();

const slide = ref<number | null>(null);
const autoplayValue = ref<number | boolean>(props.autoplay);

onMounted(async () => {
    await request();

    if (data.value.length) {
        slide.value = data.value[0].Id;
    }
});

let timeoutId: NodeJS.Timeout;

const scrollHandler = () => {
    if (timeoutId) {
        clearTimeout(timeoutId);
    }

    autoplayValue.value = false;

    timeoutId = setTimeout(() => {
        autoplayValue.value = props.autoplay;
    }, 500);
};
</script>
<template>
    <announcements-block v-if="data.length">
        <q-carousel
            v-model="slide"
            animated
            infinite
            swipeable
            :autoplay="autoplayValue"
            transition-prev="slide-right"
            transition-next="slide-left"
            :navigation="data.length > 1"
            navigation-position="bottom"
            navigation-icon="none"
            control-color="primary"
            control-type="regular"
            :height="height"
            class="announcements q-pb-lg"
        >
            <q-carousel-slide v-for="item in data" :key="item.Id" :name="item.Id" class="q-pa-none">
                <q-scroll-area class="fit" @scroll="scrollHandler">
                    <h3 class="q-ma-none text-body1 text-primary text-bold">{{ item.Subject[activeLocale] }}</h3>
                    <p class="space-pre-wrap">{{ item.Body[activeLocale] }}</p>
                </q-scroll-area>
            </q-carousel-slide>
        </q-carousel>
    </announcements-block>
</template>
