import { z } from 'zod';
import { LocaleSchema } from '~/plugins/i18n/_types';
import { zDbId, zNonEmptyString } from '~/plugins/zod/utils';
import { CountryCodeSchema, InstitutionLinkSchema, LanguageCodeSchema } from '~/api/links/_types';

export const CountryLinkSchema = z.object({
    Code: CountryCodeSchema,
    Name: z.record(LocaleSchema, z.string()),
});

export const CountryInfoSchema = CountryLinkSchema.and(
    z.object({
        IsActive: z.boolean(),
    })
);

export const FetchCountriesResponseSchema = z.array(CountryInfoSchema);

export const LanguageLinkSchema = z.object({
    Code: LanguageCodeSchema,
    Name: z.record(LocaleSchema, z.string()),
});

export const LanguageInfoSchema = CountryLinkSchema.and(
    z.object({
        IsActive: z.boolean(),
        IsLatin: z.boolean(),
    })
);

export const FetchLanguagesResponseSchema = z.array(LanguageInfoSchema);

export const EmployerInfoSchema = z.object({
    Id: zDbId(),
    Name: z.string(),
    Country: CountryLinkSchema,
    Industry: z.string(),
    Url: z.string(),
    Institution: InstitutionLinkSchema.nullable(),
});

export const FetchEmployersResponseSchema = z.array(EmployerInfoSchema);

export const ChangeNameCountryFormDataSchema = z.object({
    Name: z.record(LocaleSchema, z.string()),
});

export const ChangeStateCountryFormDataSchema = z.object({
    IsActive: z.boolean(),
});

export const ChangeNameLanguageFormDataSchema = z.object({
    Name: z.record(LocaleSchema, z.string()),
});

export const ChangeStateLanguageFormDataSchema = z.object({
    IsActive: z.boolean(),
});

export const EmployerFormDataSchema = z.object({
    Name: zNonEmptyString(),
    CountryCode: CountryCodeSchema,
    Url: zNonEmptyString(),
    Industry: zNonEmptyString(),
});

export type CountryLink = z.infer<typeof CountryLinkSchema>;
export type CountryInfo = z.infer<typeof CountryInfoSchema>;
export type FetchCountriesResponse = z.infer<typeof FetchCountriesResponseSchema>;
export type LanguageLink = z.infer<typeof LanguageLinkSchema>;
export type LanguageInfo = z.infer<typeof LanguageInfoSchema>;
export type FetchLanguagesResponse = z.infer<typeof FetchLanguagesResponseSchema>;
export type EmployerInfo = z.infer<typeof EmployerInfoSchema>;
export type FetchEmployersResponse = z.infer<typeof FetchEmployersResponseSchema>;
export type ChangeNameCountryFormData = z.infer<typeof ChangeNameCountryFormDataSchema>;
export type ChangeStateCountryFormData = z.infer<typeof ChangeStateCountryFormDataSchema>;
export type ChangeNameLanguageFormData = z.infer<typeof ChangeNameLanguageFormDataSchema>;
export type ChangeStateLanguageFormData = z.infer<typeof ChangeStateLanguageFormDataSchema>;
export type EmployerFormData = z.infer<typeof EmployerFormDataSchema>;
