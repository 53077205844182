import { defineStore } from 'pinia';
import { AnnouncementInfo } from '~/api/announcements/_types';

export const useAnnouncementStore = defineStore('announcement', () => {
    const activeAnnouncement = ref<AnnouncementInfo | null>(null);

    const setActiveAnnouncement = (data: AnnouncementInfo | null) => {
        activeAnnouncement.value = data;
    };

    const reset = () => {
        activeAnnouncement.value = null;
    };

    return {
        activeAnnouncement,
        setActiveAnnouncement,
        reset,
    };
});
