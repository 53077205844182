import { AppPlugin } from '~/plugins/_types';
import i18n from '~/plugins/i18n';
import { configure } from 'vee-validate';
import { FieldValidationMetaInfo } from '@vee-validate/i18n';

export default {
    name: 'veeValidate',
    install: async (app) => {
        // i18n message resolver
        // see zod plugin for error message validation (will override this implementation for error messages)
        configure({
            generateMessage: ({ label, rule, field, name }: FieldValidationMetaInfo) => {
                const fieldName = label || i18n.global.t(`validation.names.${name}`, field);
                const params = [fieldName, ...(rule?.params as unknown[])];

                return i18n.global.t(`validation.fields.${name}.${rule?.name}`, params, {
                    default: i18n.global.t(`validation.messages.${rule?.name}`, params, {
                        default: `[${rule?.name} - missing translation]`,
                    }),
                });
            },
        });
    },
    priority: 5,
} satisfies AppPlugin;
