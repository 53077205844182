import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { ApiHandler, ApiResponseContext } from '~/plugins/apiClient/_types';
import { ConfigDataInfo, ConfigDataInfoSchema } from './_types';
import { transformZodSchemaFn } from '~/plugins/zod/utils';

const apiEndpointPrefix = '';

export const fetchConfigRequest: ApiHandler<ApiResponseContext<ConfigDataInfo | null>> = async () => {
    const url = formatApiUrl(apiEndpointPrefix, 'config');
    return api.get(url, {
        transform: transformZodSchemaFn(ConfigDataInfoSchema, url),
    });
};
