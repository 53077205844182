import { EmailTemplateInfo } from '~/api/emailTemplates/_types';

export const useEmailTemplateDetailStore = defineStore('emailTemplate', () => {
    const activeEmailTemplate = ref<EmailTemplateInfo | null>(null);

    const reset = () => {
        activeEmailTemplate.value = null;
    };

    return {
        activeEmailTemplate,
        reset,
    };
});
