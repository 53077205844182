export const NOT_FOUND_ROUTE = 'all';
export const ACCESS_DENIED_ROUTE = 'access-denied';
export const HOME_ROUTE = 'index';
export const AUTH_LOGIN_ROUTE = 'auth-login';
export const AUTH_FORGOT_PASSWORD_ROUTE = 'auth-forgot-password';
export const AUTH_CHANGE_PASSWORD_ROUTE = 'auth-change-password-token';
export const AUTH_SET_PASSWORD_ROUTE = 'auth-set-password-token';

export const PUBLIC_ROUTES = [
    NOT_FOUND_ROUTE,
    ACCESS_DENIED_ROUTE,
    AUTH_LOGIN_ROUTE,
    AUTH_FORGOT_PASSWORD_ROUTE,
    AUTH_CHANGE_PASSWORD_ROUTE,
    AUTH_SET_PASSWORD_ROUTE,
];
