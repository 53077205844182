<script lang="ts" setup>
import { useAppStore } from '~/stores/app';
import { getNavigationConfig } from '~/configs/navigation';

const navigationConfig = getNavigationConfig();
const appStore = useAppStore();
</script>

<template>
    <q-drawer side="left" v-model="appStore.isLeftNavigationOpen" bordered :width="250">
        <div class="q-px-md q-py-md">
            <logo-img clickable style="width: 100%; height: auto" />
        </div>
        <q-list v-for="(n, nKey) in navigationConfig" :key="nKey">
            <app-drawer-navigation-item :nav="n" />
        </q-list>
    </q-drawer>
</template>
