import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { ApiHandler, ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import { AuthUser, AuthUserSchema } from '~/plugins/auth/_types';
import { transformZodSchemaFn } from '~/plugins/zod/utils';
import {
    AuthLoginFormData,
    AuthLoginResponse,
    AuthLoginResponseSchema,
    ChangePasswordFormData,
    ForgotPasswordFormData,
    ResetPasswordFormData,
    ValidateTokenResponse,
    ValidateTokenResponseSchema,
} from './_types';

const apiEndpointPrefix = 'auth';

export const loginRequest: ApiHandlerWithData<
    AuthLoginFormData,
    ApiResponseContext<AuthLoginResponse, AuthLoginFormData>
> = async (values) => {
    const url = formatApiUrl(apiEndpointPrefix, 'login');
    return api.post(url, values, { transform: transformZodSchemaFn(AuthLoginResponseSchema, url) });
};

export const logoutRequest: ApiHandler = async () => {
    return api.post(formatApiUrl(apiEndpointPrefix, 'logout'));
};

export const fetchUserRequest: ApiHandler<ApiResponseContext<AuthUser>> = async () => {
    const url = formatApiUrl(apiEndpointPrefix, 'info');
    return api.get(url, { transform: transformZodSchemaFn(AuthUserSchema, url) });
};

export const forgotPasswordRequest: ApiHandlerWithData<
    ForgotPasswordFormData,
    ApiResponseContext<undefined, ForgotPasswordFormData>
> = async (values) => {
    const url = formatApiUrl(apiEndpointPrefix, 'forgot-password');
    return api.post(url, values);
};

export const resetPasswordRequest: ApiHandlerWithData<
    { token: string; data: ResetPasswordFormData },
    ApiResponseContext<undefined, ResetPasswordFormData>
> = async ({ token, data }) => {
    const url = formatApiUrl(apiEndpointPrefix, 'reset-password');
    return api.post(url, { Token: token, ...data });
};

export const changePasswordRequest: ApiHandlerWithData<
    ChangePasswordFormData,
    ApiResponseContext<undefined, ChangePasswordFormData>
> = async (data) => {
    const url = formatApiUrl(apiEndpointPrefix, 'password');
    return api.put(url, data);
};

export const validateResetPasswordTokenRequest: ApiHandlerWithData<
    string,
    ApiResponseContext<ValidateTokenResponse>
> = async (token) => {
    const url = formatApiUrl(apiEndpointPrefix, `reset-password/validate-token/${token}`);
    return api.get(url, { transform: transformZodSchemaFn(ValidateTokenResponseSchema, url) });
};
