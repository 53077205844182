import { AppUserType } from '~/enums/permissions/_types';

export const usePermissionsAdmin = () => {
    const { can, canExceptSelf } = usePermissions();

    const canManage = () => can(AppUserType.Admin);

    const canUserCreate = () => can(AppUserType.Admin);

    const canUserEdit = (userId: number) => canExceptSelf(userId, AppUserType.Admin);

    const canUserResetPassword = (userId: number) => canExceptSelf(userId, AppUserType.Admin);

    return {
        canManage,
        canUserCreate,
        canUserEdit,
        canUserResetPassword,
    };
};
