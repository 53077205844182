/* eslint-disable  @typescript-eslint/no-explicit-any */

/**
 * Debug message if env is DEV
 * @param data
 */
export const debug = (...data: any[]) => {
    if (import.meta.env.DEV) {
        console.log(...data);
    }
};

/**
 * Debug error message if env is DEV
 * @param data
 */
export const debugError = (...data: any[]) => {
    if (import.meta.env.DEV) {
        console.error(...data);
    }
};

/**
 * Async timeout
 * @param ms
 */
export const asyncTimeout = (ms: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
};
