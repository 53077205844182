import { AppUserType } from '~/enums/permissions/_types';

export const usePermissions = () => {
    const { loggedUser } = useAuthUser();

    const can = (...types: AppUserType[]): boolean => {
        if (loggedUser.value == null) return false;

        if (loggedUser.value.Type === AppUserType.Admin) return true;

        return types.includes(loggedUser.value.Type);
    };

    const canExceptSelf = (userId: number, ...types: AppUserType[]) => {
        if (loggedUser.value?.Id === userId) return false;

        return can(...types);
    };

    return {
        can,
        canExceptSelf,
    };
};
