import { createRouter, createWebHistory } from 'vue-router';
import { setupLayouts } from 'virtual:generated-layouts';
import generatedRoutes from 'virtual:generated-pages';
import { afterEach, beforeEach } from '~/plugins/router/middlewares';
import { CustomRouteMeta } from '~/plugins/router/_types';

declare module 'vue-router' {
    interface RouteMeta extends CustomRouteMeta {}
}

const _createRouter = () => {
    const router = createRouter({
        history: createWebHistory(),
        routes: setupLayouts(generatedRoutes),
        scrollBehavior: (to, from, savedPosition) => {
            // todo: in testing
            return { left: 0, top: 0 };
        },
    });

    router.beforeEach(beforeEach);
    router.afterEach(afterEach);

    return router;
};

export default _createRouter;
