import { isArray, isEmpty } from 'lodash-es';
import { FetchListFilterOptions, FetchListOptions, FetchListSortOptions } from './_types';

const QUERY_NULL = 'null';

export const transformFetchListOptionsToQuery = (opt?: FetchListOptions | null) => {
    let query: {
        limit?: number;
        offset?: number;
        sort?: string;
        search?: string;
        filter?: Record<string, Record<string, unknown>>;
    } = {};

    if (opt?.pagination != null) {
        query = {
            ...opt.pagination,
        };
    }

    if (opt?.sort != null) {
        query.sort = getSortColumn(opt.sort);
    }

    if (opt?.search != null) {
        query.search = opt.search;
    }

    if (opt?.filter != null) {
        query.filter = getFilterQuery(opt.filter);
    }

    return query;
};

const getSortColumn = (sort: FetchListSortOptions): string => {
    return sort.descending ? `-${sort.column}` : sort.column;
};

const getFilterQuery = (filter: FetchListFilterOptions[]) => {
    const filterQuery: Record<string, Record<string, unknown[] | unknown>> = {};

    filter.forEach((f) => {
        if (isEmpty(f.column) || isEmpty(f.op)) return;

        if (!(f.column in filterQuery)) {
            filterQuery[f.column] = {};
        }

        if (!(f.op in filterQuery[f.column])) {
            filterQuery[f.column][f.op] = [];
        }

        // should always be array
        const filterVals = filterQuery[f.column][f.op];
        if (isArray(filterVals)) {
            filterVals.push(f.val == null ? QUERY_NULL : f.val);
        }
    });

    // If there is only one value in array then we remove the array and use value directly.
    // The reason is that some columns only supports one value, so we cannot send it as array.
    // If there are more than one value in unsupported columns, we do not fix it here but in the
    // component that created the filter array input (because transformer does not have context
    // what should have multiple values and what should not).
    for (const col in filterQuery) {
        for (const op in filterQuery[col]) {
            const filterVals = filterQuery[col][op];

            if (isArray(filterVals) && filterVals.length === 1) {
                filterQuery[col][op] = filterVals[0];
            }
        }
    }
    return filterQuery;
};
