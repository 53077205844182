import { AppUserType } from '~/enums/permissions/_types';

export default () => {
    const { can } = usePermissions();

    return {
        canCreate: () => can(AppUserType.Admin),
        canOverview: () => can(AppUserType.Admin),
        canDetail: () => can(AppUserType.Admin),
    };
};
