<template>
    <div class="row no-wrap" style="border: 1px solid var(--q-primary)">
        <div class="bg-primary text-on-primary q-pa-sm">
            <q-icon name="las la-bell" size="md" />
        </div>
        <div class="full-width col-shrink q-px-md q-py-sm">
            <slot></slot>
        </div>
    </div>
</template>
