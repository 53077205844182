<script lang="ts" setup>
import { omit, pick } from 'lodash-es';
import { getNavigationConfig } from '~/configs/navigation';

const navigationConfig = getNavigationConfig();
const iconNav: string[] = ['home'];

const requiredConfig = pick(navigationConfig, iconNav);
const desktopConfig = omit(navigationConfig, iconNav);
</script>
<template>
    <div class="row items-center">
        <app-header-navigation-item v-for="(nav, navKey) in requiredConfig" :key="navKey" :nav="nav" />
    </div>
    <div class="row items-center gt-sm">
        <app-header-navigation-item v-for="(nav, navKey) in desktopConfig" :key="navKey" :nav="nav" />
    </div>
</template>
