import { AppPlugin } from '~/plugins/_types';
import { Loading, Notify, Dialog, Quasar, QuasarIconSet } from 'quasar';
import iconSet from 'quasar/icon-set/line-awesome';
import '~/css/index.scss';
import { cloneDeep } from 'lodash-es';
import { quasarLanguages } from '~/plugins/i18n';
import { Locale } from '~/plugins/i18n/_types';
import { getLocaleIsoCode } from '~/utils/localeUtils';
import { debug } from '~/utils/debugUtils';

export default {
    name: 'quasar',
    install: async (app) => {
        // fixme https://github.com/quasarframework/quasar/issues/16046
        // @ts-ignore
        app.use(Quasar, {
            iconSet: createCustomIconSet(),
            plugins: {
                Loading,
                Notify,
                Dialog,
            },
            config: {
                notify: {
                    position: 'top-right',
                    timeout: 5000,
                    textColor: 'white',
                    classes: 'app-notify',
                },
            },
        });
    },
    priority: 5,
} satisfies AppPlugin;

const createCustomIconSet = (): QuasarIconSet => {
    const customIconSet = cloneDeep(iconSet);

    customIconSet.pagination.first = 'las la-angle-double-left';
    customIconSet.pagination.last = 'las la-angle-double-right';
    customIconSet.pagination.prev = 'las la-angle-left';
    customIconSet.pagination.next = 'las la-angle-right';

    customIconSet.table.firstPage = 'las la-angle-double-left';
    customIconSet.table.lastPage = 'las la-angle-double-right';
    customIconSet.table.prevPage = 'las la-angle-left';
    customIconSet.table.nextPage = 'las la-angle-right';

    customIconSet.datetime.arrowLeft = 'las la-angle-left';
    customIconSet.datetime.arrowRight = 'las la-angle-right';

    customIconSet.tabs.left = 'las la-angle-left';
    customIconSet.tabs.right = 'las la-angle-right';
    customIconSet.tabs.up = 'las la-angle-up';
    customIconSet.tabs.down = 'las la-angle-down';

    customIconSet.carousel.left = 'las la-angle-left';
    customIconSet.carousel.right = 'las la-angle-right';
    customIconSet.carousel.up = 'las la-angle-up';
    customIconSet.carousel.down = 'las la-angle-down';

    return customIconSet;
};

/**
 * Changes locale for quasar components
 * NOTE @plachtova: for some reason if this method it put to other file, there will be error with import
 * if someone knows how to fix it, please put it in file `~/plugins/i18n/locale.ts`
 * @param newLocale
 */
export const changeQuasarLocale = (newLocale: Locale) => {
    try {
        quasarLanguages[`/node_modules/quasar/lang/${getLocaleIsoCode(newLocale)}.mjs`]().then(
            /* eslint-disable */
            (lang: any) => {
                /* eslint-enable */
                Quasar.lang.set(lang.default);
            }
        );
    } catch (e) {
        debug('[Plugin Quasar]', `Failed to load '${newLocale}' lang, maybe missing .mjs?`, e);
    }
};
