import api from '~/plugins/apiClient';
import { ApiHandler, ApiHandlerWithArgs, ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import { FetchListOptions, PaginationMeta, PaginationMetaSchema } from '~/api/_types';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { transformZodSchemaFn } from '~/plugins/zod/utils';
import {
    AnnouncementFormData,
    AnnouncementInfo,
    AnnouncementInfoSchema,
    AnnouncementsArrayDashboardInfo,
    AnnouncementsArrayDashboardInfoSchema,
    AnnouncementsArrayInfo,
    AnnouncementsArrayInfoSchema,
} from './_types';
import { transformFetchListOptionsToQuery } from '~/api/_transformers';
import { API_DATE_FORMAT } from '~/constants/_constants';

const apiEndpointPrefix = 'announcements';

export const fetchDashboardAnnouncementsRequest: ApiHandler<
    ApiResponseContext<AnnouncementsArrayDashboardInfo | null>
> = async () => {
    const url = formatApiUrl('', 'dashboard/announcements');
    return api.get(url, {
        transform: transformZodSchemaFn(AnnouncementsArrayDashboardInfoSchema, url),
    });
};

export const fetchAnnouncementsRequest: ApiHandlerWithArgs<
    FetchListOptions,
    ApiResponseContext<AnnouncementsArrayInfo | null, null, PaginationMeta>
> = async (opt) => {
    const url = formatApiUrl(apiEndpointPrefix, '');
    return api.get(url, {
        transform: transformZodSchemaFn(AnnouncementsArrayInfoSchema, url),
        transformMeta: transformZodSchemaFn(PaginationMetaSchema, url),
        params: transformFetchListOptionsToQuery(opt),
    });
};

export const postAnnouncementRequest: ApiHandlerWithData<
    AnnouncementFormData,
    ApiResponseContext<AnnouncementInfo | null, AnnouncementFormData>
> = async (data) => {
    const url = formatApiUrl(apiEndpointPrefix, '');
    return api.post(url, transformAnnouncementFormData(data), {
        transform: transformZodSchemaFn(AnnouncementInfoSchema, url),
    });
};

export const putAnnouncementRequest: ApiHandlerWithData<
    { id: number; data: AnnouncementFormData },
    ApiResponseContext<AnnouncementInfo | null, AnnouncementFormData>
> = async ({ id, data }) => {
    const url = formatApiUrl(apiEndpointPrefix, `${id}`);
    return api.put(url, transformAnnouncementFormData(data), {
        transform: transformZodSchemaFn(AnnouncementInfoSchema, url),
    });
};

export const deleteAnnouncementRequest: ApiHandlerWithData<
    number,
    ApiResponseContext<AnnouncementInfo | null, null>
> = async (id) => {
    const url = formatApiUrl(apiEndpointPrefix, `${id}`);
    return api.delete(url, { transform: transformZodSchemaFn(AnnouncementInfoSchema, url) });
};

const transformAnnouncementFormData = (data: AnnouncementFormData) => {
    return {
        ...data,
        VisibleFrom: data.VisibleFrom.toFormat(API_DATE_FORMAT),
        VisibleTo: data.VisibleTo.toFormat(API_DATE_FORMAT),
    };
};
