import { RouteLocation, RouteLocationRaw, Router } from 'vue-router';
import { ACCESS_DENIED_ROUTE, HOME_ROUTE, NOT_FOUND_ROUTE, PUBLIC_ROUTES } from '~/plugins/router/_constants';

/**
 * Resolve a router path (fallback to index)
 */
export const resolveRouteFromQuery = (router: Router, toNameFallback = HOME_ROUTE): RouteLocation => {
    const { currentRoute } = router;
    const toName = <string>currentRoute.value.query?.to || toNameFallback;

    try {
        const params = JSON.parse(<string>currentRoute.value.query?.params);
        return router.resolve({ name: toName, params });
    } catch (e) {
        return router.resolve({ name: toNameFallback });
    }
};

export const goToNotFoundPage = async (router: Router, keepFullPath?: string): Promise<void> => {
    await replacePageAndKeepUrl(router, { name: NOT_FOUND_ROUTE }, keepFullPath);
};

export const goToAccessDeniedPage = async (router: Router, keepFullPath?: string): Promise<void> => {
    await replacePageAndKeepUrl(router, { name: ACCESS_DENIED_ROUTE }, keepFullPath);
};

export const replacePageAndKeepUrl = async (
    router: Router,
    to: RouteLocationRaw,
    keepFullPath?: string
): Promise<void> => {
    const fullPath = keepFullPath ?? router.currentRoute.value.fullPath;
    await router.replace(to);
    history.replaceState(null, '', fullPath);
};

export const isPublicRoute = (routeName: string): boolean => PUBLIC_ROUTES.includes(routeName);
