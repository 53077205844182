import { RouteLocationNormalized } from 'vue-router';

export enum RedirectType {
    NoRedirect = 'NoRedirect',
    AccessDenied = 'AccessDenied',
    NotFound = 'NotFound',
}

export type RoutePageRedirects = Record<string, (to: RouteLocationNormalized) => RedirectType | Promise<RedirectType>>;

export interface CustomRouteMeta {
    backlink?: RouteLocationNormalized;
}
