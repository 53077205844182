import {
    fetchEnumProgramsRequest,
    fetchProgramDetailRequest,
    fetchProgramNotesRequest,
    fetchProgramsRequest,
    postProgramAddFilesRequest,
    patchProgramChangeStateRequest,
    postProgramNoteRequest,
    postProgramRequest,
    putProgramRequest,
    deleteProgramNoteRequest,
    fetchProgramRevisionDetailRequest,
    fetchProgramRevisionsRequest,
    putProgramRevisionRequest,
    postProgramRevisionRequest,
    deleteProgramRevisionRequest,
    fetchProgramDateRelatedInstitutionRevisionsRequest,
} from '~/api/programs';
import type {
    FetchEnumProgramsReponse,
    FetchProgramNotesReponse,
    FetchProgramsReponse,
    ProgramChangeStateFormData,
    ProgramDetail,
    ProgramFormData,
    ProgramNoteFormData,
    ProgramNoteInfo,
    ProgramRevisionFormData,
} from '~/api/programs/_types';
import type { FetchDetailOptions, FetchListOptions } from '~/api/_types';
import type { ApiHandlerWithData, ApiSimpleResponseContext, FormHandlerWithArgs } from '~/plugins/apiClient/_types';
import router from '~/plugins/router';
import { useProgramDetailStore } from '~/stores/programDetail';
import {
    doRequestAndStoreData,
    fetchAndStoreData,
    resolveFormRequest,
    updateAndStoreData,
    updateAndStoreFormData,
} from '~/services/utils';
import type { ProgramState } from '~/enums/_types';

export const fetchPrograms = async (
    opt?: FetchListOptions
): Promise<ApiSimpleResponseContext<FetchProgramsReponse | null>> => {
    return await fetchProgramsRequest(opt);
};

export const fetchEnumPrograms = async (
    opt?: FetchListOptions
): Promise<ApiSimpleResponseContext<FetchEnumProgramsReponse | null>> => {
    return await fetchEnumProgramsRequest(opt);
};

export const fetchProgramNotes = async (
    programDisplayId: number,
    opt?: FetchListOptions
): Promise<ApiSimpleResponseContext<FetchProgramNotesReponse | null>> => {
    return await fetchProgramNotesRequest({ programDisplayId, opt });
};

export const fetchProgramDetail = async (
    displayId: number,
    opt?: FetchDetailOptions
): Promise<ApiSimpleResponseContext<ProgramDetail | null>> => {
    return await fetchAndStoreData(
        router,
        'DisplayId',
        displayId,
        getActiveProgram,
        async () => await fetchProgramDetailRequest(displayId),
        opt
    );
};

export const fetchProgramRevisionDetail = async (
    displayId: number,
    opt?: FetchDetailOptions
): Promise<ApiSimpleResponseContext<ProgramDetail | null>> => {
    return await fetchAndStoreData(
        router,
        'DisplayId',
        displayId,
        getActiveProgram,
        async () => await fetchProgramRevisionDetailRequest(displayId),
        opt
    );
};

export const createProgram: FormHandlerWithArgs<
    number,
    ProgramFormData,
    ApiSimpleResponseContext<ProgramDetail | null>
> = async (institutionDisplayId, values, ctx) => {
    return await updateAndStoreFormData(
        ctx,
        getActiveProgram,
        async () => await postProgramRequest({ institutionDisplayId, data: values })
    );
};

export const updateProgram: FormHandlerWithArgs<
    number,
    ProgramFormData,
    ApiSimpleResponseContext<ProgramDetail | null>
> = async (displayId, values, ctx) => {
    return await updateAndStoreFormData(
        ctx,
        getActiveProgram,
        async () => await putProgramRequest({ displayId, data: values })
    );
};

export const updateProgramRevision: FormHandlerWithArgs<
    number,
    ProgramRevisionFormData,
    ApiSimpleResponseContext<ProgramDetail | null>
> = (id, values, ctx) =>
    updateAndStoreFormData(ctx, getActiveProgram, () => putProgramRevisionRequest({ id, data: values }));

export const createProgramRevision: FormHandlerWithArgs<
    number,
    ProgramRevisionFormData,
    ApiSimpleResponseContext<ProgramDetail | null>
> = (displayId, values, ctx) =>
    updateAndStoreFormData(ctx, getActiveProgram, () => postProgramRevisionRequest({ displayId, data: values }));

export const deleteProgramRevision = (id: number) =>
    doRequestAndStoreData(getActiveProgram, () => deleteProgramRevisionRequest(id));

export const changeStateProgram: ApiHandlerWithData<
    { programDisplayId: number; newState: ProgramState },
    ApiSimpleResponseContext<ProgramDetail | null>
> = async ({ programDisplayId, newState }) => {
    return await updateAndStoreData(
        getActiveProgram,
        async () => await patchProgramChangeStateRequest({ displayId: programDisplayId, data: { State: newState } })
    );
};

export const changeStateWithRatingProgram: FormHandlerWithArgs<
    number,
    ProgramChangeStateFormData,
    ApiSimpleResponseContext<ProgramDetail | null>
> = async (displayId, values, ctx) => {
    return await updateAndStoreFormData(
        ctx,
        getActiveProgram,
        async () => await patchProgramChangeStateRequest({ displayId, data: values })
    );
};

export const addFileProgram: ApiHandlerWithData<
    { programDisplayId: number; fileId: string },
    ApiSimpleResponseContext<ProgramDetail | null>
> = async ({ programDisplayId, fileId }) => {
    return await updateAndStoreData(
        getActiveProgram,
        async () => await postProgramAddFilesRequest({ displayId: programDisplayId, data: { Files: [fileId] } })
    );
};

export const addNoteProgram: FormHandlerWithArgs<
    number,
    ProgramNoteFormData,
    ApiSimpleResponseContext<ProgramNoteInfo | null>
> = async (programDisplayId, values, ctx) => {
    return await resolveFormRequest(async () => await postProgramNoteRequest({ programDisplayId, data: values }), ctx);
};

export const deleteNoteProgram = async (
    programDisplayId: number
): Promise<ApiSimpleResponseContext<ProgramNoteInfo | null>> => {
    return await deleteProgramNoteRequest(programDisplayId);
};

export const fetchInstitutionRevisionRelatedToProgramDate = (
    displayId: number,
    validFrom?: Date | null | undefined,
    opt?: FetchListOptions
) => fetchProgramDateRelatedInstitutionRevisionsRequest({ displayId, validFrom, opt });

const getActiveProgram = () => storeToRefs(useProgramDetailStore()).activeProgram;

export const fetchProgramsRevisions = (displayId: number, opt?: FetchListOptions) =>
    fetchProgramRevisionsRequest({ displayId, opt });
