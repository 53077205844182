import {
    ApiRequestConfig,
    ApiResponse,
    ApiServiceOpts,
    ApiStatusCode,
    HttpStatusCodeExtended,
} from '~/plugins/apiClient/_types';
import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    HttpStatusCode,
    InternalAxiosRequestConfig,
    Method,
} from 'axios';
import { hasFiles, normalizeUrl } from '~/utils/baseUtils';
import appConfig from '~/plugins/config';
import { isUndefined, merge } from 'lodash-es';
import { useAuthStore } from '~/plugins/auth';
import { parseRawResponse, transformResponseData } from '~/plugins/apiClient/response';
import { Notify } from 'quasar';
import { t } from '~/plugins/i18n';
import { debug } from '~/utils/debugUtils';
import { serialize } from 'object-to-formdata';

const apiClient = (endpointUrl: string, serviceOpts?: ApiServiceOpts) => {
    const _reportableErrors: ApiStatusCode[] = [
        HttpStatusCode.Forbidden,
        HttpStatusCode.NotFound,
        HttpStatusCode.MethodNotAllowed,
        HttpStatusCode.TooManyRequests,
        HttpStatusCode.ServiceUnavailable,
        HttpStatusCode.InternalServerError,
    ];
    const _authErrors: ApiStatusCode[] = [HttpStatusCodeExtended.CsrfTokenMismatch, HttpStatusCode.Unauthorized];

    const _baseUrl: string = normalizeUrl(serviceOpts?.axios?.baseURL || appConfig.api.baseURL);
    const _defaultConfig: ApiRequestConfig = merge(serviceOpts?.axios || { ...appConfig.api }, {
        baseURL: `${_baseUrl}${endpointUrl}`,
    });

    const _debug: boolean = serviceOpts?.debug || false;
    const _notifyOnError: boolean = serviceOpts?.notifyOnError || false;

    const _axios: AxiosInstance = axios.create(_defaultConfig);

    // > Interceptors
    const _handleRequest = (request: InternalAxiosRequestConfig) => {
        if (!serviceOpts?.public) {
            const authStore = useAuthStore();
            request.headers.setAuthorization(`Bearer ${authStore.token?.Token}`);
        }

        return request;
    };

    const _handleResponse = async (rawResponse: AxiosResponse): Promise<ApiResponse> => parseRawResponse(rawResponse);
    const _handleError = async ({ response: rawResponse }: AxiosError): Promise<ApiResponse> => {
        if (isUndefined(rawResponse)) {
            throw new Error('[ApiClient] Network error.');
        }

        const response = await parseRawResponse(rawResponse);

        if (_authErrors.includes(response.status) && serviceOpts?.onAuthErrorFn) {
            await serviceOpts.onAuthErrorFn();
        }

        if (_debug) {
            debug(response);
        }

        return response;
    };

    _axios.interceptors.request.use(_handleRequest);
    _axios.interceptors.response.use(_handleResponse, _handleError);

    // > HTTP Methods
    const _doRequest = async <T = unknown, D = unknown, M = unknown>(
        url: string,
        method: Method,
        requestConfig?: ApiRequestConfig
    ): Promise<ApiResponse<T, D>> => {
        const baseUrl = normalizeUrl(requestConfig?.baseURL || appConfig.api.baseURL);
        const axiosConfig: AxiosRequestConfig = {
            ...requestConfig,
            url,
            method,
        };

        if (requestConfig?.endpointUrl) {
            axiosConfig.baseURL = `${baseUrl}${requestConfig.endpointUrl}`;
        }

        if (hasFiles(axiosConfig.data)) {
            axiosConfig.transformRequest = [(data) => serialize(data)];
        }

        const response = await _axios.request<T, ApiResponse<T>, D>(axiosConfig);

        const notifyOnError = requestConfig?.notifyOnError ?? _notifyOnError;

        if (_reportableErrors.includes(response.status) && notifyOnError) {
            Notify.create({
                group: 'api:client',
                type: 'negative',
                message: t('app.errors.title'),
                caption: t(response.errors._reason),
                actions: [{ icon: 'las la-times', color: 'white' }],
                timeout: 10000,
            });
        }

        return {
            ...response,
            data: transformResponseData<T>(response.data, requestConfig?.transform),
            meta: transformResponseData<M>(response.meta, requestConfig?.transformMeta),
        };
    };

    const requestAction = async <T = unknown, D = unknown>(
        url: string,
        method: Method,
        requestConfig?: ApiRequestConfig
    ): Promise<ApiResponse<T, D>> => {
        return _doRequest<T, D>(url, method, requestConfig);
    };

    const getAction = async <T = unknown, D = unknown>(
        url: string,
        requestConfig?: ApiRequestConfig
    ): Promise<ApiResponse<T, D>> => {
        return requestAction<T, D>(url, 'get', requestConfig);
    };

    const postAction = async <T = unknown, D = unknown>(
        url: string,
        data?: unknown,
        requestConfig?: ApiRequestConfig
    ): Promise<ApiResponse<T, D>> => {
        requestConfig = { ...requestConfig, data };
        return requestAction<T, D>(url, 'post', requestConfig);
    };

    const putAction = async <T = unknown, D = unknown>(
        url: string,
        data?: unknown,
        requestConfig?: ApiRequestConfig
    ): Promise<ApiResponse<T, D>> => {
        requestConfig = { ...requestConfig, data };
        return requestAction<T, D>(url, 'put', requestConfig);
    };

    const patchAction = async <T = unknown, D = unknown>(
        url: string,
        data?: unknown,
        requestConfig?: ApiRequestConfig
    ): Promise<ApiResponse<T>> => {
        requestConfig = { ...requestConfig, data };
        return requestAction<T, D>(url, 'patch', requestConfig);
    };

    const deleteAction = async <T = unknown, D = unknown>(
        url: string,
        requestConfig?: ApiRequestConfig
    ): Promise<ApiResponse<T>> => {
        return requestAction<T, D>(url, 'delete', requestConfig);
    };

    debug('[apiClient]', { endpointUrl, serviceOpts });

    return {
        request: requestAction,
        get: getAction,
        post: postAction,
        put: putAction,
        patch: patchAction,
        delete: deleteAction,
    };
};

export default apiClient;
