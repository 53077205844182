import { InstitutionInfo } from '~/api/institutions/_types';

export const useInstitutionDetailStore = defineStore('insititution', () => {
    const activeInstitution = ref<InstitutionInfo | null>(null);

    const reset = () => {
        activeInstitution.value = null;
    };

    return {
        activeInstitution,
        reset,
    };
});
