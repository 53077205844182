import { ProgramDetail } from '~/api/programs/_types';

export const useProgramDetailStore = defineStore('program', () => {
    const activeProgram = ref<ProgramDetail | null>(null);

    const reset = () => {
        activeProgram.value = null;
    };

    return {
        activeProgram,
        reset,
    };
});
