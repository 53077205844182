import config from '~/plugins/config';
import localeStorage from '~/plugins/i18n/store';
import { AppLocaleInfo } from './_types';
import { Locale } from '~/plugins/i18n/_types';
import { updateUserPreferences } from '~/services/user';
import { assign } from 'lodash-es';

export const useAppLocale = () => {
    const { availableLocales } = config.app;

    const appLocales = computed<AppLocaleInfo[]>(() => {
        return availableLocales.map((locale) => ({
            label: locale.toUpperCase(),
            value: locale,
        }));
    });

    const { loggedUser } = useAuthUser();

    const setActiveLocale = async (locale: Locale) => {
        const { isSuccess, data } = await updateUserPreferences(
            assign(loggedUser.value?.Preferences, { Locale: locale })
        );

        if (isSuccess && data) {
            localeStorage.activeLocale.value = data.Locale;
        }

        return data;
    };

    return {
        activeLocale: localeStorage.activeLocale,
        availableLocales,
        appLocales,
        setActiveLocale,
    };
};
