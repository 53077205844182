import { FetchListFilterOptions, FetchListOptions } from '~/api/_types';
import { AppTablePagination } from '~/components/base/tables/_types';
import { fetchPrograms } from '~/services/programs';

export default (initialPagination?: AppTablePagination | null, implicitFilter?: FetchListFilterOptions[] | null) => {
    const { data, pagination, userFilter, isLoading, request } = useApiCollectionData(
        async (opt?: FetchListOptions) => await fetchPrograms(opt),
        initialPagination,
        implicitFilter
    );

    return {
        data,
        pagination,
        userFilter,
        isLoading,
        request,
    };
};
