import { AxiosHeaders, RawAxiosResponseHeaders } from 'axios';
import { ContentDisposition } from './_types';

/**
 * Format API endpoint URL
 * @param routePrefix
 * @param path
 */
export const formatApiUrl = (routePrefix: string, path: string | number = ''): string => {
    return `${routePrefix}/${path}`;
};

export const parseContentDisposition = (apiResponse: {
    headers: RawAxiosResponseHeaders | AxiosHeaders;
}): ContentDisposition => {
    const contentDispositionHeader = apiResponse.headers['content-disposition'];

    if (!contentDispositionHeader) {
        console.error('Couldnt parse filename from response.');
        console.error(apiResponse);
        return { filename: null };
    }

    return {
        filename: new RegExp('filename="(.*)"', 'gm').exec(contentDispositionHeader)?.[1] ?? null,
    };
};
