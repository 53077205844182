import { z } from 'zod';
import { AppUserType } from '~/enums/permissions/_types';
import { getFullName } from '~/utils/userUtils';
import { zDbId, zTimestamp } from '~/plugins/zod/utils';
import { InstitutionLinkSchema } from '~/api/links/_types';
import { LocaleSchema } from '~/plugins/i18n/_types';

export const UserPreferencesSchema = z.object({
    Locale: LocaleSchema,
});

const AuthUserSharedSchema = z
    .object({
        Id: zDbId(),
        FirstName: z.string(),
        LastName: z.string(),
        Email: z.string().email().optional(), // TODO: remove optional
        Preferences: UserPreferencesSchema.nullable(),
    })
    .transform((val) => ({
        ...val,
        FullName: getFullName(val),
    }));

export const AuthUserSchema = z
    .discriminatedUnion('Type', [
        z.object({ Type: z.literal(AppUserType.Client), Institution: InstitutionLinkSchema }),
        ...(
            Object.values(AppUserType).filter((x) => x !== AppUserType.Client) as Exclude<
                AppUserType,
                AppUserType.Client
            >[]
        ).map((x) =>
            z.object({
                Type: z.literal(x),
                Institution: z.null(),
            })
        ),
    ])
    .and(AuthUserSharedSchema);

export const AuthTokenSchema = z.object({
    ExpiresAt: zTimestamp(),
    Token: z.string(),
});

export type UserPreferences = z.infer<typeof UserPreferencesSchema>;
export type AuthUser = z.infer<typeof AuthUserSchema>;
export type AuthToken = z.infer<typeof AuthTokenSchema>;
