import {
    changePasswordRequest,
    fetchUserRequest,
    forgotPasswordRequest,
    loginRequest,
    logoutRequest,
    resetPasswordRequest,
    validateResetPasswordTokenRequest,
} from '~/api/auth';
import {
    AuthLoginFormData,
    ChangePasswordFormData,
    ForgotPasswordFormData,
    ResetPasswordFormData,
    ValidateTokenResponse,
} from '~/api/auth/_types';
import { TOKEN_INVALID_STATUS_CODE } from '~/api/_constants';
import {
    ApiSimpleResponseContext,
    FormHandler,
    FormHandlerWithArgs,
    SubmissionCtxParam,
    isApiGenericErrorsData,
} from '~/plugins/apiClient/_types';
import { useAuthStore } from '~/plugins/auth';
import { AuthUser } from '~/plugins/auth/_types';
import { t } from '~/plugins/i18n';
import { resolveFormRequest } from '~/services/utils';
import { finishAutologin, finishLogin, finishLogout } from './utils';

export const fetchUser = async (force: boolean = false): Promise<ApiSimpleResponseContext<AuthUser | null>> => {
    const { user } = useAuthStore();
    if (user != null && !force) return { isSuccess: true, data: user };

    return await fetchUserRequest();
};

export const login: FormHandler<AuthLoginFormData> = async (values, { setErrors }) => {
    const { data: user, isSuccess: isSuccessLoginRequest, isValidationError, errors } = await loginRequest(values);

    if (!isSuccessLoginRequest || user == null) {
        setErrors(
            isValidationError
                ? errors
                : {
                      Login: t('auth.login.form.errors.invalidCredentials'),
                      Password: t('auth.login.form.errors.invalidCredentials'),
                  }
        );
        return;
    }

    await finishLogin(user);
};

export const tryAutoLogin = async (): Promise<boolean> => {
    const authStore = useAuthStore();

    if (authStore.tokenExpired || authStore.loggedIn) {
        return false;
    }

    const { isSuccess, data } = await fetchUser(true);

    if (isSuccess && data != null) {
        finishAutologin(data);
    }

    return isSuccess;
};

export const logout = async (silent: boolean = false): Promise<void> => {
    await logoutRequest();
    await finishLogout(silent);
};

export const forgotPassword: FormHandler<ForgotPasswordFormData, { isSuccess: boolean }> = async (values, ctx) => {
    return await resolveFormRequest(async () => await forgotPasswordRequest(values), ctx);
};

export const resetPassword: FormHandlerWithArgs<
    string,
    ResetPasswordFormData,
    { isSuccess: boolean; isTokenInvalid: boolean }
> = async (token, values, ctx) => {
    const { isSuccess, isValidationError, errors } = await resolveFormRequest(
        async () => await resetPasswordRequest({ token, data: values }),
        ctx
    );

    if (isValidationError && isApiGenericErrorsData(errors) && errors.Status === TOKEN_INVALID_STATUS_CODE)
        return { isSuccess: false, isTokenInvalid: true };

    if (isSuccess) {
        await finishLogout(true);
    }

    return { isSuccess, isTokenInvalid: false };
};

export const resetPasswordCurry =
    (token: string) => (formData: ResetPasswordFormData, ctx: SubmissionCtxParam<ResetPasswordFormData>) =>
        resetPassword(token, formData, ctx);

export const changePassword: FormHandler<ChangePasswordFormData, { isSuccess: boolean }> = async (values, ctx) => {
    return await resolveFormRequest(async () => await changePasswordRequest(values), ctx);
};

export const validateResetPasswordToken = async (
    token: string
): Promise<ApiSimpleResponseContext<ValidateTokenResponse | null>> => {
    return await validateResetPasswordTokenRequest(token);
};
