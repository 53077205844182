import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { ApiHandler, ApiHandlerWithArgs, ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import { transformZodSchemaFn } from '~/plugins/zod/utils';
import { transformFetchListOptionsToQuery } from '~/api/_transformers';
import { FetchListOptions, PaginationMetaSchema } from '~/api/_types';
import {
    CertificateDetail,
    CertificateDetailSchema,
    CertificatesQueueInfo,
    CertificatesQueueInfoSchema,
    FetchCertificatesResponse,
    FetchCertificatesResponseSchema,
} from './_types';
import { LanguageCode } from '../links/_types';

const apiEndpointCertPrefix = 'certificates';
const apiEndpointQueuePrefix = 'queue';

export const fetchCertificatesRequest: ApiHandlerWithArgs<
    FetchListOptions,
    ApiResponseContext<FetchCertificatesResponse | null>
> = async (opt) => {
    const url = formatApiUrl(apiEndpointCertPrefix);
    return api.get(url, {
        transform: transformZodSchemaFn(FetchCertificatesResponseSchema, url),
        transformMeta: transformZodSchemaFn(PaginationMetaSchema, url),
        params: transformFetchListOptionsToQuery(opt),
    });
};

export const fetchCertificateDetailRequest: ApiHandlerWithData<number, ApiResponseContext<CertificateDetail>> = async (
    id
) => {
    const url = formatApiUrl(apiEndpointCertPrefix, id);
    return api.get(url, { transform: transformZodSchemaFn(CertificateDetailSchema, url) });
};

export const fetchCertificatesQueueRequest: ApiHandler<ApiResponseContext<CertificatesQueueInfo | null>> = async () => {
    const url = formatApiUrl(apiEndpointQueuePrefix);
    return api.get(url, {
        transform: (responseContext: CertificatesQueueInfo) =>
            responseContext?.Data === null
                ? null
                : transformZodSchemaFn(CertificatesQueueInfoSchema, url)(responseContext),
    });
};

export const putCertificateQueueReorderRequest: ApiHandlerWithData<
    { code: LanguageCode; reorder: number[] },
    ApiResponseContext<null>
> = async ({ code, reorder }) => {
    const url = formatApiUrl(apiEndpointQueuePrefix, `${code}/reorder`);
    return api.post(url, { CertificateIds: reorder });
};

export const postCertificateQueueRequest: ApiHandlerWithData<number, ApiResponseContext<null>> = async (id) => {
    const url = formatApiUrl(apiEndpointCertPrefix, `${id}/queue`);
    return api.post(url);
};

export const deleteCertificateQueueRequest: ApiHandlerWithData<number, ApiResponseContext<null>> = async (id) => {
    const url = formatApiUrl(apiEndpointCertPrefix, `${id}/queue`);
    return api.delete(url);
};

export const postCertificateQueuePrintRequest: ApiHandlerWithData<LanguageCode, ApiResponseContext<null>> = (code) => {
    const url = formatApiUrl(apiEndpointQueuePrefix, `${code}/print`);
    return api.post(url, undefined, { responseType: 'blob' });
};

export const postQueuePrintRequest: ApiHandlerWithData<LanguageCode, ApiResponseContext<null>> = (code) =>
    api.post(formatApiUrl(apiEndpointQueuePrefix, `${code}/print`));

export const postCertificatePrintRequest: ApiHandlerWithData<number, ApiResponseContext<null>> = (id) => {
    const url = formatApiUrl(apiEndpointCertPrefix, `${id}/print`);
    return api.post(url);
};
