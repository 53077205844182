{
  "errors": {
    "invalid_type": "Expected %{expected}, received %{received}",
    "invalid_received_empty": "This value is required",
    "invalid_literal": "Invalid literal value, expected %{expected}",
    "unrecognized_keys": "Unrecognized key(s) in object: %{keys}",
    "invalid_union": "Invalid input",
    "invalid_union_discriminator": "Invalid discriminator value. Expected %{options}",
    "invalid_enum_value": "Invalid enum value. Expected %{options}, received '%{received}'",
    "invalid_arguments": "Invalid function arguments",
    "invalid_return_type": "Invalid function return type",
    "invalid_date": "Invalid date",
    "custom": "Invalid input",
    "invalid_intersection_types": "Intersection results could not be merged",
    "not_multiple_of": "Number must be a multiple of %{multipleOf}",
    "not_finite": "Number must be finite",
    "invalid_string": {
      "email": "Invalid %{validation}",
      "url": "Invalid %{validation}",
      "uuid": "Invalid %{validation}",
      "cuid": "Invalid %{validation}",
      "regex": "Invalid",
      "datetime": "Invalid %{validation}",
      "startsWith": "Invalid input: must start with '%{startsWith}'",
      "endsWith": "Invalid input: must end with '%{endsWith}'"
    },
    "too_small": {
      "array": {
        "exact": "Array must contain exactly %{minimum} element(s)",
        "inclusive": "Array must contain at least %{minimum} element(s)",
        "not_inclusive": "Array must contain more than %{minimum} element(s)"
      },
      "string": {
        "exact": "String must contain exactly %{minimum} character(s)",
        "inclusive": "String must contain at least %{minimum} character(s)",
        "not_inclusive": "String must contain over %{minimum} character(s)"
      },
      "number": {
        "exact": "Number must be exactly %{minimum}",
        "inclusive": "Number must be greater than or equal to %{minimum}",
        "not_inclusive": "Number must be greater than %{minimum}"
      },
      "set": {
        "exact": "Invalid input",
        "inclusive": "Invalid input",
        "not_inclusive": "Invalid input"
      },
      "date": {
        "exact": "Date must be exactly %{minimum}",
        "inclusive": "Date must be greater than or equal to %{minimum}",
        "not_inclusive": "Date must be greater than %{minimum}"
      }
    },
    "too_big": {
      "array": {
        "exact": "Array must contain exactly %{maximum} element(s)",
        "inclusive": "Array must contain at most %{maximum} element(s)",
        "not_inclusive": "Array must contain less than %{maximum} element(s)"
      },
      "string": {
        "exact": "String must contain exactly %{maximum} character(s)",
        "inclusive": "String must contain at most %{maximum} character(s)",
        "not_inclusive": "String must contain under %{maximum} character(s)"
      },
      "number": {
        "exact": "Number must be exactly %{maximum}",
        "inclusive": "Number must be less than or equal to %{maximum}",
        "not_inclusive": "Number must be less than %{maximum}"
      },
      "set": {
        "exact": "Invalid input",
        "inclusive": "Invalid input",
        "not_inclusive": "Invalid input"
      },
      "date": {
        "exact": "Date must be exactly %{maximum}",
        "inclusive": "Date must be smaller than or equal to %{maximum}",
        "not_inclusive": "Date must be smaller than %{maximum}"
      }
    }
  },
  "custom": {
    "no_match": "Values do not match",
    "password_no_match": "Password does not match",
    "invalid_file": "Invalid type. Expected 'File'"

  },
  "validations": {
    "email": "email",
    "url": "url",
    "uuid": "uuid",
    "cuid": "cuid",
    "regex": "regex",
    "datetime": "datetime"
  }
}