import { fetchConfigRequest } from '~/api/config';
import { ConfigDataInfo } from '~/api/config/_types';
import { ApiSimpleResponseContext } from '~/plugins/apiClient/_types';
import { useConfigStore } from '~/stores/config';

export const fetchConfig = async (): Promise<ApiSimpleResponseContext<ConfigDataInfo | null>> => {
    const result = await fetchConfigRequest();

    if (result.isSuccess && result.data) {
        const store = useConfigStore();
        store.setConfigData(result.data);
    }

    return result;
};
