import { z } from 'zod';
import { API_DATE_FORMAT } from '~/constants/_constants';
import { CertificateState, CetificateType } from '~/enums/_types';
import { zDbId, zLuxonDateTime } from '~/plugins/zod/utils';
import { getFullName } from '~/utils/userUtils';
import { AppFileInfoSchema } from '../files/_types';
import {
    AppUserLinkSchema,
    InstitutionLinkSchema,
    LanguageCodeSchema,
    ProgramLinkSchema,
    StudentDataSchema,
} from '../links/_types';
import { OrderLinkSchema } from '../orders/_types';

export const CertificateLinkSchema = z
    .object({
        Id: zDbId(),
        DegreeBefore: z.string().nullable(),
        FirstName: z.string(),
        LastName: z.string(),
        DegreeAfter: z.string().nullable(),
        LanguageCode: LanguageCodeSchema,
        File: AppFileInfoSchema.nullable(),
        Type: z.nativeEnum(CetificateType),
        State: z.nativeEnum(CertificateState),
    })
    .transform((data) => ({
        ...data,
        FullName: getFullName(data),
    }));

export const CertificateInfoSchema = z.object({
    Id: zDbId(),
    OrderId: zDbId(),
    Institution: InstitutionLinkSchema.nullable(),
    Program: ProgramLinkSchema.nullable(),
    FinishedAt: zLuxonDateTime({ format: API_DATE_FORMAT }).nullable(),
    CreatedAt: zLuxonDateTime({ iso: true }),
    Student: StudentDataSchema,
    LanguageCode: LanguageCodeSchema,
    File: AppFileInfoSchema.nullable(),
    Type: z.nativeEnum(CetificateType),
    State: z.nativeEnum(CertificateState),
});

export const CertificateDetailSchema = z.object({
    Id: zDbId(),
    Institution: InstitutionLinkSchema.nullable(),
    Program: ProgramLinkSchema.nullable(),
    Order: OrderLinkSchema,
    Student: StudentDataSchema,
    User: AppUserLinkSchema.nullable(),
    LanguageCode: LanguageCodeSchema,
    File: AppFileInfoSchema.nullable(),
    Type: z.nativeEnum(CetificateType),
    State: z.nativeEnum(CertificateState),
});

export const FetchCertificatesResponseSchema = CertificateInfoSchema.array();
export const CertificatesQueueInfoSchema = z.record(LanguageCodeSchema, CertificateInfoSchema.array()).nullable();

export type CertificateLink = z.infer<typeof CertificateLinkSchema>;
export type CertficateInfo = z.infer<typeof CertificateInfoSchema>;
export type CertificateDetail = z.infer<typeof CertificateDetailSchema>;
export type FetchCertificatesResponse = z.infer<typeof FetchCertificatesResponseSchema>;
export type CertificatesQueueInfo = z.infer<typeof CertificatesQueueInfoSchema>;
