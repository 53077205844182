import { fetchDashboardAnnouncements } from '~/services/announcements';

const useDashboardAnnouncements = () => {
    const { data, pagination, userFilter, isLoading, request } = useApiCollectionData(async () => {
        return await fetchDashboardAnnouncements();
    });

    return {
        data,
        pagination,
        userFilter,
        isLoading,
        request,
    };
};

export default useDashboardAnnouncements;
