import { OrderDetail } from '~/api/orders/_types';

export const useOrderDetailStore = defineStore('order', () => {
    const activeOrder = ref<OrderDetail | null>(null);

    const reset = () => {
        activeOrder.value = null;
    };

    return {
        activeOrder,
        reset,
    };
});
