<script lang="ts" setup>
import { logout } from '~/services/auth';

const { loggedUser } = useAuthUser();

const onLogout = async () => {
    await logout();
};
</script>
<template>
    <flat-btn-dropdown dense hide-dropdown-icon class="q-ml-sm">
        <template #label>
            <user-avatar v-if="loggedUser" :full-name="loggedUser.FullName" :size="$q.screen.gt.sm ? '56px' : '32px'" />
        </template>

        <q-list>
            <q-item clickable dense active-class="text-bold text-primary" :to="{ name: 'profile' }">
                <q-item-section>
                    {{ $t('pages.profile') }}
                </q-item-section>
            </q-item>
            <q-item clickable dense active-class="text-bold text-primary" @click="onLogout">
                <q-item-section>
                    {{ $t('auth.logOut.title') }}
                </q-item-section>
            </q-item>
        </q-list>
    </flat-btn-dropdown>
</template>
