import { z } from 'zod';

export enum AppUserType {
    Admin = 'Admin',
    Approver = 'Approver',
    Client = 'Client',
    Student = 'Student',
}

export const AppUserTypeSchema = z.nativeEnum(AppUserType);
