import router from '~/plugins/router';
import { FetchDetailOptions, FetchListOptions } from '~/api/_types';
import {
    ApiHandlerWithData,
    ApiSimpleResponseContext,
    FormHandler,
    FormHandlerWithArgs,
} from '~/plugins/apiClient/_types';
import { fetchAndStoreData, updateAndStoreData, updateAndStoreFormData } from '~/services/utils';
import { useOrderDetailStore } from '~/stores/orderDetail';
import {
    fetchOrderDetailRequest,
    fetchOrdersRequest,
    patchOrderChangePublishAtRequest,
    patchOrderChangeStateRequest,
    postOrderCertificatesQueueRequest,
    postOrderIciRequest,
    postOrderRequest,
} from '~/api/orders';
import {
    FetchOrdersResponse,
    OrderChangePublishAtFormData,
    OrderChangeStateFormData,
    OrderDetail,
    OrderFormData,
    OrderIciFormData,
} from '~/api/orders/_types';
import { OrderState } from '~/enums/_types';

export const fetchOrders = async (
    opt?: FetchListOptions
): Promise<ApiSimpleResponseContext<FetchOrdersResponse | null>> => {
    return await fetchOrdersRequest(opt);
};

export const fetchOrderDetail = async (
    id: number,
    opt?: FetchDetailOptions
): Promise<ApiSimpleResponseContext<OrderDetail | null>> => {
    return await fetchAndStoreData(
        router,
        'Id',
        id,
        getActiveOrder,
        async () => await fetchOrderDetailRequest(id),
        opt
    );
};

export const createOrder: FormHandler<OrderFormData, ApiSimpleResponseContext<OrderDetail | null>> = (values, ctx) =>
    updateAndStoreFormData(ctx, getActiveOrder, () => postOrderRequest(values));

export const createOrderIci: FormHandler<OrderIciFormData, ApiSimpleResponseContext<OrderDetail | null>> = async (
    values,
    ctx
) => {
    return await updateAndStoreFormData(ctx, getActiveOrder, async () => await postOrderIciRequest(values));
};

export const changeStateOrder: ApiHandlerWithData<
    { orderId: number; newState: OrderState },
    ApiSimpleResponseContext<OrderDetail | null>
> = async ({ orderId, newState }) => {
    return await updateAndStoreData(
        getActiveOrder,
        async () => await patchOrderChangeStateRequest({ id: orderId, data: { State: newState } })
    );
};

export const changeStateWithDataOrder: FormHandlerWithArgs<
    number,
    OrderChangeStateFormData,
    ApiSimpleResponseContext<OrderDetail | null>
> = async (id, values, ctx) => {
    return await updateAndStoreFormData(
        ctx,
        getActiveOrder,
        async () => await patchOrderChangeStateRequest({ id, data: values })
    );
};

export const changePublishAtOrder: FormHandlerWithArgs<
    number,
    OrderChangePublishAtFormData,
    ApiSimpleResponseContext<OrderDetail | null>
> = async (id, values, ctx) => {
    return await updateAndStoreFormData(
        ctx,
        getActiveOrder,
        async () => await patchOrderChangePublishAtRequest({ id, data: values })
    );
};

export const addOrderCertificatesToQueue: ApiHandlerWithData<number, ApiSimpleResponseContext<null>> = async (id) => {
    return await postOrderCertificatesQueueRequest(id);
};

const getActiveOrder = () => storeToRefs(useOrderDetailStore()).activeOrder;
