import { useTitle } from '@vueuse/core';
import { t } from '~/plugins/i18n';
import { isUndefined } from 'lodash-es';
import { RouteLocationNormalized } from 'vue-router';
import { Locale } from '~/plugins/i18n/_types';
import config from '~/plugins/config';
import localeIsoCodes from '~/plugins/i18n/isoCodes.json';

/**
 * Set page title
 * @param route
 */
export const setPageTitle = (route: RouteLocationNormalized): void => {
    const pageName = route.name?.toString();

    if (isUndefined(pageName)) {
        useTitle(t('app.title'));
        return;
    }

    useTitle(t(`pages.${pageName}`), { titleTemplate: `${t('pages.main_title')} | %s` });
};

/**
 * Get locale ISO Code
 * @param locale
 */
export const getLocaleIsoCode = (locale: Locale | string): string =>
    localeIsoCodes[<Locale>locale] || config.app.locale;
