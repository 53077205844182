import { ProgramDetail } from '~/api/programs/_types';
import { AppUserType } from '~/enums/permissions/_types';

export const usePermissionsPrograms = () => {
    const { can } = usePermissions();

    const canCreate = () => can(AppUserType.Admin, AppUserType.Approver, AppUserType.Client);
    const canChangeState = (program?: ProgramDetail) => program?.NextStates.length;
    const canEdit = (program?: ProgramDetail) => program?.Can.Update;
    const canOverview = () => can(AppUserType.Admin, AppUserType.Approver, AppUserType.Client);
    const canDetail = () => can(AppUserType.Admin, AppUserType.Approver, AppUserType.Client);
    const canAddNote = () => can(AppUserType.Admin, AppUserType.Approver, AppUserType.Client);
    const canDeleteNote = () => can(AppUserType.Admin);
    const canUploadFile = (program?: ProgramDetail) => program?.Can.AppendFiles;
    const canDeleteFile = () => can(AppUserType.Admin);
    const canUpdateCompetences = (program?: ProgramDetail) => program?.Can.UpdateCompetences;

    return {
        canCreate,
        canChangeState,
        canEdit,
        canOverview,
        canDetail,
        canAddNote,
        canDeleteNote,
        canUploadFile,
        canDeleteFile,
        canUpdateCompetences,
    };
};
