import { RedirectType, RoutePageRedirects } from '~/plugins/router/_types';

let _pageRedirects: RoutePageRedirects | null = null;

export const getPageRedirects = () => {
    if (_pageRedirects == null) {
        _pageRedirects = redirects();
    }
    return _pageRedirects;
};

const redirects = (): RoutePageRedirects => {
    const { canManage: canManageAdminSection } = usePermissionsAdmin();

    const {
        canOverview: canInstitutionsOverview,
        canCreate: canInstitutionCreate,
        canDetail: canInstitutionDetail,
    } = usePermissionsInstitutions();

    const {
        canOverview: canProgramsOverview,
        canCreate: canProgramCreate,
        canDetail: canProgramsDetail,
    } = usePermissionsPrograms();

    const {
        canOverview: canOrdersOverview,
        canCreate: canOrdersCreate,
        canCreateIci: canOrdersCreateIci,
        canDetail: canOrdersDetail,
    } = usePermissionsOrders();

    const { canOverview: canQueueOverview } = usePermissionsQueue();

    return {
        admin: () => (canManageAdminSection() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'admin-users': () => (canManageAdminSection() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'admin-languages': () => (canManageAdminSection() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'admin-countries': () => (canManageAdminSection() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'admin-employers': () => (canManageAdminSection() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'admin-files': () => (canManageAdminSection() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'admin-announcements': () => (canManageAdminSection() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'admin-announcements-new': () =>
            canManageAdminSection() ? RedirectType.NoRedirect : RedirectType.AccessDenied,
        'admin-announcements-id': () => (canManageAdminSection() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        institutions: () => (canInstitutionsOverview() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'institutions-new': () => (canInstitutionCreate() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'institutions-id': () => (canInstitutionDetail() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'institutions-id-new-program': () => (canProgramCreate() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        programs: () => (canProgramsOverview() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'programs-new': () => (canProgramCreate() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'programs-id': () => (canProgramsDetail() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        orders: () => (canOrdersOverview() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'orders-new': () => (canOrdersCreate() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'orders-new-ici': () => (canOrdersCreateIci() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'orders-id': () => (canOrdersDetail() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'print-queue': () => (canQueueOverview() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
    };
};
