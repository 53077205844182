import { RefinementCtx } from 'zod';
import { InstitutionName, InstitutionNameFormData } from './_types';
import { REQUIRED_LANG } from '~/constants/_constants';

export const mergeInstitutionName = (name: InstitutionNameFormData): string => {
    const lines = [name.Line1];
    if (name.Line2 != null) lines.push(name.Line2);
    if (name.Line3 != null) lines.push(name.Line3);

    return lines.join('\n');
};

export const splitInstitutionName = (name: string): InstitutionName | null => {
    const splitName = name.split(/\r?\n/);

    if (!splitName.length) return null;

    return {
        Orig: name,
        Line3: splitName.length === 3 ? splitName.pop() : undefined,
        Line2: splitName.length === 2 ? splitName.pop() : undefined,
        Line1: splitName.length === 1 ? splitName.pop() ?? '' : '',
    };
};

export const validateInstitutionName = (
    institutionNames: Record<string, string>,
    hasError: boolean,
    ctx: RefinementCtx
) => {
    for (const language in institutionNames) {
        if (institutionNames[language] != null) {
            continue;
        }

        ctx.addIssue({
            code: 'custom',
            message: `Name is empty for lang '${language}'`,
        });

        return true;
    }

    return hasError;
};

export const institutionNameTransform = (institutionNames: Record<string, string>) => {
    const splitName: Record<string, InstitutionName> = {};

    for (const language in institutionNames) {
        const name = splitInstitutionName(institutionNames[language]);

        if (name != null) {
            splitName[language] = name;
            continue;
        }
    }

    return splitName;
};

export const validateRequiredName = (requiredName: InstitutionName | null, hasError: boolean, ctx: RefinementCtx) => {
    if (requiredName == null) {
        ctx.addIssue({
            code: 'custom',
            message: `Name should have required lang '${REQUIRED_LANG}'`,
        });
        return true;
    }

    return hasError;
};
