<script lang="ts" setup>
import { AppUserType } from '~/enums/permissions/_types';
import { FetchListFilterOptions } from '~/api/_types';
import { FilterOperator, OrderState, ProgramState } from '~/enums/_types';

const { loggedUser } = useAuthUser();

const moreRouteName = computed(() => {
    if (loggedUser.value?.Type === AppUserType.Approver) return 'programs';
    if (loggedUser.value?.Type === AppUserType.Student) return 'certificates';
    return 'orders';
});

const implicitFilterSettings = computed<FetchListFilterOptions[] | null>(() => {
    if (loggedUser.value?.Type === AppUserType.Admin) {
        return [
            { column: 'State', op: FilterOperator.Eq, val: OrderState.Created },
            { column: 'State', op: FilterOperator.Eq, val: OrderState.AwaitingPayment },
        ];
    }

    if (loggedUser.value?.Type === AppUserType.Approver) {
        return [{ column: 'State', op: FilterOperator.Eq, val: ProgramState.SentForCertification }];
    }

    if (loggedUser.value?.Type === AppUserType.Client) {
        return [{ column: 'State', op: FilterOperator.Eq, val: OrderState.AwaitingPayment }];
    }

    return null;
});
</script>
<template>
    <page-base v-if="loggedUser != null">
        <template #header>
            <page-header :title="$t('dashboard.title')" :subtitle="$t('app.short-name')"> </page-header>
        </template>

        <announcements-carousel height="150px" />

        <h2 class="row items-center justify-between a-text-28 text-weight-bold q-my-lg">
            <div>
                {{ $t(`dashboard.tableTitle.${loggedUser.Type}`) }}
            </div>
            <contained-btn :to="{ name: moreRouteName }" :label="$t('dashboard.actions.more')" />
        </h2>

        <programs-dashboard-table
            v-if="loggedUser.Type === AppUserType.Approver"
            :implicit-filter="implicitFilterSettings"
        />
        <certificates-dashboard-table
            v-else-if="loggedUser.Type === AppUserType.Student"
            :implicit-filter="implicitFilterSettings"
        />
        <orders-dashboard-table v-else :implicit-filter="implicitFilterSettings" />
    </page-base>
</template>
