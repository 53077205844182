{
  "errors": {
    "invalid_type": "Typ vstupu musí být '%{expected}', ale byl obdržen typ '%{received}'",
    "invalid_received_empty": "Toto pole je povinné",
    "invalid_literal": "Neplatná doslovná hodnota, očekává se: %{expected}",
    "unrecognized_keys": "Nerozpoznané klíče v objektu: %{keys}",
    "invalid_union": "Neplatný vstup",
    "invalid_union_discriminator": "Neplatná hodnota diskriminátoru. Očekává se '%{options}'",
    "invalid_enum_value": "Neplatná hodnota výčtu. Očekává se '%{options}', ale bylo obdrženo '%{received}'",
    "invalid_arguments": "Neplatné argumenty funkce",
    "invalid_return_type": "Neplatný typ návratové hodnoty",
    "invalid_date": "Neplatné datum",
    "custom": "Neplatný vstup",
    "invalid_intersection_types": "Hodnoty průniku nelze sloučit",
    "not_multiple_of": "Číslo musí být násobkem %{multipleOf}",
    "not_finite": "Číslo nesmí být nekonečné",
    "invalid_string": {
      "email": "Neplatný %{validation}",
      "url": "Neplatná %{validation}",
      "uuid": "Neplatný %{validation}",
      "cuid": "Neplatný %{validation}",
      "regex": "Neplatná kombinace",
      "datetime": "Neplatné %{validation}",
      "startsWith": "Neplatný vstup: musí začínat '%{startsWith}'",
      "endsWith": "Neplatný vstup: musí končit '%{endsWith}'"
    },
    "too_small": {
      "array": {
        "exact": "Pole musí obsahovat přesně %{minimum} prvek | Pole musí obsahovat přesně %{minimum} prvky | Pole musí obsahovat přesně %{minimum} prvků",
        "inclusive": "Pole musí obsahovat alespoň %{minimum} prvek | Pole musí obsahovat alespoň %{minimum} prvky | Pole musí obsahovat alespoň %{minimum} prvků",
        "not_inclusive": "Pole musí obsahovat více než %{minimum} prvek | Pole musí obsahovat více než %{minimum} prvky | Pole musí obsahovat více než %{minimum} prvků"
      },
      "string": {
        "exact": "Text musí obsahovat přesně %{minimum} znak | Text musí obsahovat přesně %{minimum} znaky | Text musí obsahovat přesně %{minimum} znaků",
        "inclusive": "Text musí obsahovat alespoň %{minimum} znak | Text musí obsahovat alespoň %{minimum} znaky | Text musí obsahovat alespoň %{minimum} znaků",
        "not_inclusive": "Text musí obsahovat více než %{minimum} znak | Text musí obsahovat více než %{minimum} znaky | Text musí obsahovat více než %{minimum} znaků"
      },
      "number": {
        "exact": "Číslo musí být přesně %{minimum}",
        "inclusive": "Číslo musí být větší nebo rovno %{minimum}",
        "not_inclusive": "Číslo musí být větší než %{minimum}"
      },
      "set": {
        "exact": "Neplatný vstup",
        "inclusive": "Neplatný vstup",
        "not_inclusive": "Neplatný vstup"
      },
      "date": {
        "exact": "Datum musí být přesně %{minimum}",
        "inclusive": "Datum musí být větší nebo rovno %{minimum}",
        "not_inclusive": "Datum musí být větší než %{minimum}"
      }
    },
    "too_big": {
      "array": {
        "exact": "Pole musí obsahovat přesně %{maximum} prvek | Pole musí obsahovat přesně %{maximum} prvky | Pole musí obsahovat přesně %{maximum} prvků",
        "inclusive": "Pole musí obsahovat alespoň %{maximum} prvek | Pole musí obsahovat alespoň %{maximum} prvky | Pole musí obsahovat alespoň %{maximum} prvků",
        "not_inclusive": "Pole musí obsahovat méně než %{maximum} prvek | Pole musí obsahovat méně než %{maximum} prvky | Pole musí obsahovat méně než %{maximum} prvků"
      },
      "string": {
        "exact": "Text musí obsahovat přesně %{maximum} znak | Text musí obsahovat přesně %{maximum} znaky | Text musí obsahovat přesně %{maximum} znaků",
        "inclusive": "Text musí obsahovat alespoň %{maximum} znak | Text musí obsahovat alespoň %{maximum} znaky | Text musí obsahovat alespoň %{maximum} znaků",
        "not_inclusive": "Text musí obsahovat méně než %{maximum} znak | Text musí obsahovat méně než %{maximum} znaky | Text musí obsahovat méně než %{maximum} znaků"
      },
      "number": {
        "exact": "Číslo musí být přesně %{maximum}",
        "inclusive": "Číslo musí být menší nebo rovno %{maximum}",
        "not_inclusive": "Číslo musí být menší než %{maximum}"
      },
      "set": {
        "exact": "Neplatný vstup",
        "inclusive": "Neplatný vstup",
        "not_inclusive": "Neplatný vstup"
      },
      "date": {
        "exact": "Datum musí být přesně %{maximum}",
        "inclusive": "Datum musí být menší nebo rovno %{maximum}",
        "not_inclusive": "Datum musí být menší než %{maximum}"
      }
    }
  },
  "custom": {
    "no_match": "Hodnoty se neshodují",
    "password_no_match": "Heslo se neschoduje",
    "invalid_file": "Typ vstupu musí být 'File'"
  },
  "validations": {
    "email": "e-mail",
    "url": "url",
    "uuid": "uuid",
    "cuid": "cuid",
    "regex": "regex",
    "datetime": "datum a čas"
  }
}