<script lang="ts" setup>
import { HOME_ROUTE } from '~/plugins/router/_constants';
import { LogoImgProps } from './_types';

const props = withDefaults(defineProps<LogoImgProps>(), {
    clickable: false,
});

const router = useRouter();

const onLogoClick = () => {
    if (props.clickable) router.push({ name: HOME_ROUTE });
};
</script>
<template>
    <img
        src="/assets/images/ies_logo.svg"
        :alt="$t('pages.main_title')"
        :class="{
            'cursor-pointer': props.clickable,
        }"
        width="248"
        height="150"
        style="object-fit: contain; object-position: center"
        @click="onLogoClick"
    />
</template>
