import { DateTime } from 'luxon';
import { QChipProps } from 'quasar';
import { FilterOperator } from '~/enums/_types';

export enum FilterDataType {
    Enum = 'enum',
    String = 'string',
    Number = 'number',
    Date = 'date',
    DateTime = 'datetime',
    Boolean = 'boolean',
}

export interface FilterBarChipProps {
    column: FilterBarColumnConfig;
    operator?: FilterBarOperator | null;
    value?: FilterBarValue;
    removable?: QChipProps['removable'];
}

export interface FilterBarProps {
    /**
     * filter columns config
     */
    columns: FilterBarColumnConfig[];

    /**
     * operators options
     * this can be used as fallback if 'getOperatorsFn' is not defined or does not return operators
     */
    operators?: FilterOperator[];

    /**
     * get operator options for column
     * used if you need to have different options for columns
     * if this metod return null then 'operators' will be used as fallback
     * if no operator options is defined, filter bar will skip operator
     * @param column - column config
     */
    getOperatorsFn?: (column: FilterBarColumnConfig) => FilterBarOperator[] | null;

    /**
     * debounce to call 'getOptionsFn'
     */
    optionsDebounce?: number;

    /**
     * get values for options menu
     * this function will be called for columns in config that have 'hasOptions = true'
     * @param phrase - search phrase for autocomplete options (only if 'autocomplete = true')
     * @param column - column config
     * @param debounce - debounce options request
     */
    getOptionsFn?: (
        phrase: string,
        column: FilterBarColumnConfig,
        debounce?: number
    ) => FilterBarOptionsResult | Promise<FilterBarOptionsResult>;

    /**
     * enables saving filter to query 'filter' in url
     * if enabled 'getOperatorItemFn' and 'getOptionItemFn' must be defined to recreate filter
     */
    enableQueryFilter?: boolean;

    /**
     * Get operator item by key
     * Used to recreate complete filter from query. Only called if 'enableQueryFilter = true'
     * @param key - operator key
     * @param column - column config
     */
    getOperatorItemFn?: (key: string, column: FilterBarColumnConfig) => FilterBarOperator | null;

    /**
     * Get options menu item by key
     * Used to recreate complete filter from query. Only called if 'enableQueryFilter = true' and 'hasOptions = true'.
     * @param key - options key
     */
    getOptionItemFn?: (
        key: string,
        column: FilterBarColumnConfig
    ) => (FilterBarValue | any | null) | Promise<FilterBarValue | any | null>; // eslint-disable-line @typescript-eslint/no-explicit-any

    /**
     * Enables search text in filter bar
     *
     */
    enableSearch?: boolean;

    /**
     * Search column filter key
     * Only used if 'enableSearch = true'. This key will be reserved and cannot be used in columns config.
     */
    searchFilterKey?: string;
    dense?: boolean;
}

export interface FilterBarColumnConfig extends FilterPair {
    /**
     * column value data type
     * data types 'boolean' and 'enum' are enforcing 'hasOptions = true'
     * data types 'date' and 'datetime' are enforcing 'hasOptions = false' (there is datepicker modal instead)
     */
    dataType: FilterDataType;
    /**
     * this column supports options menu or not
     * if true, then 'getOptionsFn', 'optionsKey' and 'optionsLabelKey' must be provided
     * if this is enabled then 'enter' key cannot confirm filter value and you must choose it from options menu
     */
    hasOptions?: boolean;
    /**
     * custom object key used for as options identifier
     * default value is 'key'
     */
    optionsKey?: string;
    /**
     * object key used for options value label
     * default value is 'label'
     */
    optionsLabelKey?: string;
    /**
     * this column supports autocomplete filtering for options
     * 'hasOptions' must be true to enable this
     * if this is enabled, user can write search phrase for value to filter options menu, otherwise writing is disabled
     */
    autocomplete?: boolean;
}

export type FilterCardGetOptionsFn = (
    phrase: string,
    column: FilterCardColumnConfig
) => FilterCardOptionsResult | Promise<FilterCardOptionsResult>;

export type FilterCardGetOptionsItemFn = (ids: string[], column: FilterCardColumnConfig) => any[] | Promise<any[]>; // eslint-disable-line @typescript-eslint/no-explicit-any

export interface FilterCardSelectProps {
    modelValue: unknown;
    label: string;
    optionsKey?: string;
    optionsLabelKey?: string | ((val: any) => string); // eslint-disable-line @typescript-eslint/no-explicit-any
    autocomplete?: boolean;
    multiple?: boolean;
    debounce?: number;
    mapOptions?: boolean;
    emitValue?: boolean;
    getOptionsFn: (phrase: string) => FilterCardOptionsResult | Promise<FilterCardOptionsResult>;
}

export interface FilterCardItemProps {
    modelValue: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    config: FilterCardColumnConfig;
    label: string;
    getOptionsFn?: FilterCardGetOptionsFn;
    optionsDebounce?: number;
    additionalFilter?: boolean;
}

export interface FilterCardProps {
    columns: FilterCardColumnConfig[];

    ignoreColumns?: string[];
    /**
     * debounce to call 'getOptionsFn' (used only for 'enums' columns with 'autocomplete = true')
     */
    optionsDebounce?: number;

    /**
     * get values for options menu
     * this function is required if there is at least one 'enum' column
     * @param phrase - search phrase for autocomplete options (only if 'autocomplete = true')
     * @param column - column config
     */
    getOptionsFn?: FilterCardGetOptionsFn;

    /**
     * get value items for options menu
     * this function is required if there is at least one 'enum' column and 'enableQueryFilter = true'
     * @param ids - ids for items we want to find
     * @param column - column config
     */
    getOptionsItemFn?: FilterCardGetOptionsItemFn;

    /**
     * enables saving filter to query 'filter' in url
     */
    enableQueryFilter?: boolean;

    /**
     * Enables search text in filter bar
     *
     */
    enableSearch?: boolean;

    /**
     * Search column filter key
     * Only used if 'enableSearch = true'. This key will be reserved and cannot be used in columns config.
     */
    searchFilterKey?: string;
    dense?: boolean;
}

export type FilterCardColumnConfig = {
    key: string;
    /**
     * If filter is additional, then 'filter' event will be fired every time value is changed,
     * otherwise 'filter' event will be fired only on filter button click.
     * Also filters will be gruped in different section visualy.
     */
    additionalFilter?: boolean;
} & (
    | {
          dataType: FilterDataType.Enum;
          label: string;
          /**
           * custom object key used for as options identifier
           * default value is 'key'
           */
          optionsKey?: string;
          /**
           * object key used for options value label
           * default value is 'label'
           */
          optionsLabelKey?: string | ((val: any) => string); // eslint-disable-line @typescript-eslint/no-explicit-any
          /**
           * this column supports autocomplete filtering for options
           * if this is enabled, user can write search phrase for value to filter options menu, otherwise writing is disabled
           */
          autocomplete?: boolean;
          /**
           * column supports multiple choice in filter
           */
          multiple?: boolean;
      }
    | {
          dataType: FilterDataType.Boolean;
          trueLabel?: unknown;
          falseLabel?: unknown;
          label: string;
      }
    | {
          dataType: FilterDataType.String;
          label: string;
      }
    | ({
          dataType: FilterDataType.Number | FilterDataType.Date | FilterDataType.DateTime;
      } & (
          | {
                mode: 'equals';
                label: string;
            }
          | {
                mode: 'range';
                labelFrom: string;
                labelTo: string;
            }
      ))
);

export interface FilterColumnConfigBase {
    key: string;
    dataType: FilterDataType;
}

export interface FilterBarOptionsResult {
    skipped: boolean;
    data: FilterBarValue[] | any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface FilterCardOptionsResult {
    skipped: boolean;
    data: unknown[];
}

export interface FilterResult {
    column: FilterColumnConfigBase;
    operator?: string;
    value: unknown;
}

export interface FilterBarResult {
    _uid: string;
    column: FilterBarColumnConfig;
    operator?: FilterBarOperator | null;
    value: FilterBarValue;
}

export type FilterCardResult = {
    _uid: string;
    column: FilterCardColumnConfig;
    label: string;
    operator: FilterOperator;
} & (
    | {
          dataType: FilterDataType.Boolean;
          // @plachtova: there is a problem with having boolean as value in qselect, therefore we return string boolean values instead
          value: 'true' | 'false' | undefined;
      }
    | {
          dataType: FilterDataType.Number;
          value: number | null | undefined;
      }
    | {
          dataType: FilterDataType.String;
          value: string | null | undefined;
      }
    | {
          dataType: FilterDataType.Enum;
          value: any; // eslint-disable-line @typescript-eslint/no-explicit-any
      }
    | {
          dataType: FilterDataType.Date | FilterDataType.DateTime;
          value: DateTime | null | undefined;
      }
);

export type FilterPair = {
    key: string;
    label: string;
};

export interface FilterBarValue extends FilterPair {
    val: unknown;
}

export interface FilterBarOperator extends FilterPair {
    hint?: string;
}

export const isFilterBarOperator = (
    item: FilterBarOperator | FilterBarColumnConfig | FilterBarValue
): item is FilterBarOperator => {
    return !isFilterBarColumnConfig(item) && !isFilterBarValue(item);
};

export const isFilterBarColumnConfig = (
    item: FilterBarOperator | FilterColumnConfigBase | FilterBarValue
): item is FilterColumnConfigBase => {
    return 'dataType' in item;
};

export const isFilterBarValue = (
    item: FilterBarOperator | FilterColumnConfigBase | FilterBarValue
): item is FilterBarValue => {
    return 'val' in item;
};

export const isFilterCardResultBooleanValue = (item: string): item is 'true' | 'false' => {
    return item === 'true' || item === 'false';
};
