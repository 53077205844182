import { AuthToken, AuthUser } from '~/plugins/auth/_types';
import browserStorage from '~/plugins/browserStorage';
import { DateTime } from 'luxon';
import { isNull } from 'lodash-es';
import config from '~/plugins/config';
import { useDirectRouter } from '~/plugins/pinia';
import { resolveRouteFromQuery } from '~/plugins/router/utils';
import { AUTH_LOGIN_ROUTE, NOT_FOUND_ROUTE } from '~/plugins/router/_constants';

export const useAuthStore = defineStore('auth', () => {
    const authStorageConfig = config.storage.data.auth;
    const router = useDirectRouter();

    const _user = ref<AuthUser | null>(null);
    const _token = ref(browserStorage.get<AuthToken | null>(authStorageConfig.key));

    const loggedIn = computed(() => !isNull(user.value));
    const tokenExpired = computed(
        () => isNull(_token.value) || DateTime.fromMillis(_token.value.ExpiresAt).diffNow('seconds').seconds <= 0
    );
    const user = computed(() => _user.value);
    const token = computed(() => (!tokenExpired.value ? _token.value : null));

    /**
     * Reset Store state
     */
    const _resetState = (): void => {
        _user.value = null;
        _token.value = null;
    };

    const setToken = (payload: AuthToken | null): void => {
        browserStorage.set(authStorageConfig.key, payload);
        _token.value = payload;
    };

    const setUser = (payload: AuthUser | null): void => {
        _user.value = payload;
    };

    const finishLogin = async (payload: AuthUser): Promise<void> => {
        const { currentRoute } = router;
        setUser(payload);

        if (currentRoute.value.name !== NOT_FOUND_ROUTE) {
            await router.push(resolveRouteFromQuery(router));
        }
    };

    const logout = async (preserveQuery = false): Promise<void> => {
        const { currentRoute } = router;

        browserStorage.remove(authStorageConfig.key);
        _resetState();

        // > Ignore if on login page
        if (currentRoute.value.name === AUTH_LOGIN_ROUTE) {
            return;
        }

        await router.replace({
            name: AUTH_LOGIN_ROUTE,
            query: preserveQuery
                ? {
                      to: <string>currentRoute.value.name,
                      params: JSON.stringify(currentRoute.value.params),
                  }
                : undefined,
        });
    };

    return {
        user,
        loggedIn,
        tokenExpired,
        token,
        setToken,
        setUser,
        finishLogin,
        logout,
    };
});

export type AuthStoreType = ReturnType<typeof useAuthStore>;

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
