<script lang="ts" setup>
import { getOrdersOverviewColumnConfig } from '~/configs/orders';
import { AppUserType } from '~/enums/permissions/_types';
import { OrdersDashboardTableProps } from './_types';

const props = withDefaults(defineProps<OrdersDashboardTableProps>(), {
    implicitFilter: null,
});

const { loggedUser } = useAuthUser();
const { isLoading, data, pagination, request } = useOrders(
    { sortBy: 'UpdatedAt', descending: true, rowsPerPage: 15 },
    props.implicitFilter
);

const columns = getOrdersOverviewColumnConfig(false);

const visibleColumns = computed(() =>
    loggedUser.value?.Type === AppUserType.Client
        ? columns.filter((x) => x.name !== 'InstitutionName').map((x) => x.name)
        : undefined
);

onMounted(async () => {
    await request();
});
</script>

<template>
    <app-table
        v-model:pagination="pagination"
        sticky-column="last"
        :loading="isLoading"
        :columns="columns"
        :visible-columns="visibleColumns"
        :rows="data"
        row-key="Id"
        :hide-bottom="data.length > 0"
        hide-pagination
        @request="request"
    >
        <template #body-cell-State="scope">
            <q-td name="State" :props="scope" class="text-bold" :class="`enum-text-orderState--${scope.row.State}`">{{
                $t(`enums.orderState.${scope.row.State}`)
            }}</q-td>
        </template>
        <template #body-cell-Actions="scope">
            <q-td key="Actions" :props="scope">
                <flat-btn
                    dense
                    :tooltip="$t('generic.tooltips.detail')"
                    icon="las la-file-alt"
                    :to="{ name: 'orders-id', params: { id: scope.row.Id } }"
                />
            </q-td>
        </template>
    </app-table>
</template>
