import { FetchListFilterOptions, FetchListOptions } from '~/api/_types';
import { AppTablePagination } from '~/components/base/tables/_types';
import { fetchOrders } from '~/services/orders';

export const useOrders = (
    initialPagination?: AppTablePagination | null,
    implicitFilter?: FetchListFilterOptions[] | null
) => {
    const { data, pagination, userFilter, isLoading, request } = useApiCollectionData(
        async (opt?: FetchListOptions) => await fetchOrders(opt),
        initialPagination,
        implicitFilter
    );

    return {
        data,
        pagination,
        userFilter,
        isLoading,
        request,
    };
};
