import api from '~/plugins/apiClient';
import { ApiHandlerWithData, ApiSimpleResponseContext } from '~/plugins/apiClient/_types';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { UserPreferences, UserPreferencesSchema } from '~/plugins/auth/_types';
import { transformZodSchemaFn } from '~/plugins/zod/utils';

const apiEndpointPrefix = 'me';

export const patchUserPreferencesRequest: ApiHandlerWithData<
    UserPreferences,
    ApiSimpleResponseContext<UserPreferences | null>
> = async (values) => {
    const url = formatApiUrl(apiEndpointPrefix, 'preferences');
    return api.patch(url, values, {
        transform: transformZodSchemaFn(UserPreferencesSchema, url),
    });
};
