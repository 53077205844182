import { AppUserType } from '~/enums/permissions/_types';
import { OrderState } from '~/enums/_types';

export const usePermissionsOrders = () => {
    const { can } = usePermissions();

    const canCreate = () => can(AppUserType.Admin, AppUserType.Client);
    const canCreateIci = () => can(AppUserType.Admin);
    const canChangeState = () => can(AppUserType.Admin, AppUserType.Client);
    const canOverview = () => can(AppUserType.Admin, AppUserType.Client);
    const canDetail = () => can(AppUserType.Admin, AppUserType.Client);
    const canDeleteFile = (state: OrderState) =>
        can(AppUserType.Admin) && (state === OrderState.AwaitingPayment || state === OrderState.Created);

    return {
        canCreate,
        canCreateIci,
        canChangeState,
        canOverview,
        canDetail,
        canDeleteFile,
    };
};
