import { HttpStatusCode } from 'axios';
import type { SubmissionContext } from 'vee-validate';
import type { Router } from 'vue-router';
import type { FetchDetailOptions, RedirectOptions } from '~/api/_types';
import {
    type ApiGenericErrorsData,
    type ApiGenericValues,
    type ApiSimpleFormResponseContext,
    type ApiSimpleResponseContext,
    type ApiSimpleWithStatusResponseContext,
    type ApiStatusCode,
    isApiGenericErrorsData,
    type ApiResponse,
} from '~/plugins/apiClient/_types';
import { goToAccessDeniedPage, goToNotFoundPage } from '~/plugins/router/utils';

export const resolveFormRequest = async <TValues extends ApiGenericValues, TResult>(
    formRequestFn: () => Promise<ApiSimpleFormResponseContext<TResult, TValues>>,
    ctx: SubmissionContext<TValues>,
    handleValidationGenericErrorFn?: (errors: ApiGenericErrorsData) => void
): Promise<ApiSimpleFormResponseContext<TResult, TValues>> => {
    const result = await formRequestFn();

    if (result.isValidationError) {
        if (!isApiGenericErrorsData(result.errors)) {
            ctx.setErrors(result.errors);
        } else if (handleValidationGenericErrorFn != null) {
            handleValidationGenericErrorFn(result.errors);
        }
    }

    return result;
};

export const fetchAndStoreData = async <TKey extends keyof TData, TData>(
    router: Router,
    key: TKey,
    keyValue: TData[TKey],
    getDataFromStoreFn: () => Ref<TData | null>,
    fetchDataFn: (key: TData[TKey]) => Promise<ApiSimpleWithStatusResponseContext<TData | null>>,
    opt?: FetchDetailOptions
): Promise<ApiSimpleResponseContext<TData | null>> => {
    const storeData = getDataFromStoreFn();

    if (storeData.value?.[key] === keyValue && !opt?.force && !opt?.noStore) {
        return { isSuccess: true, data: storeData.value };
    }

    const { isSuccess, data, status } = await fetchDataFn(keyValue);

    if (!opt?.noStore && !router.currentRoute.value.name?.toString().startsWith(opt?.routeNamePrefix ?? '')) {
        return { isSuccess: false, data: null };
    }

    if (isSuccess && !opt?.noStore) {
        storeData.value = data;
    } else {
        await redirectIfApiError(router, status, opt);
    }

    return { isSuccess, data };
};

/**
 * @param args
 * @param values
 * @param setErrors
 */
export const updateAndStoreData: <TValues extends ApiGenericValues, TResult>(
    getDataFromStoreFn: () => Ref<TResult>,
    updateRequestFn: () => Promise<ApiSimpleFormResponseContext<TResult, TValues>>
) => Promise<ApiSimpleFormResponseContext<TResult, TValues>> = async (getDataFromStoreFn, updateRequestFn) => {
    const result = await updateRequestFn();

    if (result.isSuccess) {
        const data = getDataFromStoreFn();
        data.value = result.data;
    }

    return result;
};

/**
 * @param args
 * @param values
 * @param setErrors
 */
export const updateAndStoreFormData: <TValues extends ApiGenericValues, TResult>(
    ctx: SubmissionContext<TValues>,
    getDataFromStoreFn: () => Ref<TResult>,
    formRequestFn: () => Promise<ApiSimpleFormResponseContext<TResult, TValues>>,
    noStore?: boolean
) => Promise<ApiSimpleFormResponseContext<TResult, TValues>> = async (
    ctx,
    getDataFromStoreFn,
    formRequestFn,
    noStore
) => {
    const result = await resolveFormRequest(formRequestFn, ctx);

    if (result.isSuccess && !noStore) {
        const data = getDataFromStoreFn();
        data.value = result.data;
    }

    return result;
};

/**
 * @param args
 * @param values
 * @param setErrors
 */
export const doRequestAndStoreData = async <TResult, TResponse>(
    getDataFromStoreFn: () => Ref<TResult>,
    requestFn: () => Promise<ApiResponse<TResult, TResponse>>,
    noStore?: boolean
) => {
    const result = await requestFn();
    //handle errors here

    if (result.isSuccess && !noStore) {
        const data = getDataFromStoreFn();
        data.value = result.data;
    }

    return result;
};

const redirectIfApiError = async (router: Router, status: ApiStatusCode, opt?: RedirectOptions): Promise<boolean> => {
    if (status === HttpStatusCode.NotFound && opt?.redirectIfNotFound) {
        await goToNotFoundPage(router);
        return true;
    }

    if (status === HttpStatusCode.Forbidden && opt?.redirectIfAccessDenied) {
        await goToAccessDeniedPage(router);
        return true;
    }

    return false;
};

export const downloadFromBlob = (file: Blob, filename: string) => {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(file);
    a.download = filename || 'file-name';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
