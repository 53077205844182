<script lang="ts" setup>
import { getCertificatesOverviewColumnConfig } from '~/configs/certificates';
import { AppUserType } from '~/enums/permissions/_types';
import { CertificatesDashboardTableProps } from './_types';

const props = withDefaults(defineProps<CertificatesDashboardTableProps>(), {
    implicitFilter: null,
});

const { loggedUser } = useAuthUser();
const { isLoading, data, pagination, request } = useCertificates(
    { sortBy: 'UpdatedAt', descending: true, rowsPerPage: 15 },
    props.implicitFilter
);

const columns = getCertificatesOverviewColumnConfig(false);

const visibleColumns = computed(() =>
    loggedUser.value?.Type === AppUserType.Client
        ? columns.filter((x) => x.name !== 'InstitutionName').map((x) => x.name)
        : undefined
);

onMounted(async () => {
    await request();
});
</script>

<template>
    <app-table
        v-model:pagination="pagination"
        sticky-column="last"
        :loading="isLoading"
        :columns="columns"
        :visible-columns="visibleColumns"
        :rows="data"
        row-key="Id"
        :hide-bottom="data.length > 0"
        hide-pagination
        @request="request"
    >
        <template #body-cell-State="scope">
            <q-td
                name="State"
                :props="scope"
                class="text-bold"
                :class="`enum-text-certificateState--${scope.row.State}`"
                >{{ $t(`enums.certificateState.${scope.row.State}`) }}</q-td
            >
        </template>
        <template #body-cell-Actions="scope">
            <q-td key="Actions" :props="scope">
                <flat-btn
                    v-if="scope.row.File"
                    dense
                    text-color="secondary"
                    :tooltip="$t('generic.tooltips.download')"
                    icon="las la-arrow-circle-down"
                    :type="scope.row.File.Extension"
                    :href="scope.row.File.Url"
                />
                <flat-btn
                    dense
                    :tooltip="$t('generic.tooltips.detail')"
                    icon="las la-file-alt"
                    :to="{ name: 'certificates-id', params: { id: scope.row.Id } }"
                />
            </q-td>
        </template>
    </app-table>
</template>
