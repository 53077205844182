import { z } from 'zod';
import { zDbId } from '~/plugins/zod/utils';

export const ConfigDataInfoSchema = z.object({
    AppVersion: z.string(),
    AvailableCurrencies: z.string().array(),
    AvailableLocales: z.string().array(),
    Password: z.object({
        MinLength: z.number(),
        MixedCase: z.boolean(),
        Numbers: z.boolean(),
        Symbols: z.boolean(),
    }),
    UploadMaxFilesize: z.number(),
    RootFolderId: zDbId(),
});

export type ConfigDataInfo = z.infer<typeof ConfigDataInfoSchema>;
