<script lang="ts" setup>
import { AppHeaderNavigationHoverBtnProps } from './_types';

defineProps<AppHeaderNavigationHoverBtnProps>();
const isMenuOpen = ref(false);

const debouncedIsMenuOpen = useDebounce(isMenuOpen, 100);

const toggleMenuOpen = (val: boolean) => (isMenuOpen.value = val);
</script>

<template>
    <flat-btn
        v-bind="$props"
        dense
        text-color="on-surface-1"
        active-color="primary"
        icon-right="las la-angle-down"
        small-icons
        @mouseenter="() => toggleMenuOpen(true)"
        @mouseleave="() => toggleMenuOpen(false)"
        @click="() => toggleMenuOpen(true)"
    />

    <q-menu
        :model-value="debouncedIsMenuOpen"
        no-parent-event
        square
        anchor="bottom right"
        self="top right"
        :offset="[-5, 0]"
        @mouseenter="() => toggleMenuOpen(true)"
        @mouseleave="() => toggleMenuOpen(false)"
        @hide="() => toggleMenuOpen(false)"
    >
        <slot></slot>
    </q-menu>
</template>
